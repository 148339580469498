<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-12 11:03:41
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-12 15:35:55
 * @FilePath: \chuwudemo\src\views\operationPage\photoAlbum\components\dialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-dialog v-model="dialogTableVisible" title="详情" width="60%">
      <el-table :data="props.detailData">
        <el-table-column prop="props.detailData" label="昵称">
          <template #default="{ row }">
            <span>{{ row.user.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :prop="item.prop" :label="item.label" v-for="(item, index) in cloData" :key="index">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
const dialogTableVisible = defineModel('dialogTableVisible')
const props = defineProps({
  detailData: {
    type: Array,
    default: []
  },
})
const cloData = reactive([
  { prop: "user_id", label: "User_ID" },
  { prop: "created_at", label: "创建时间" },
  { prop: "updated_at", label: "更新时间" },
]) //列表的头
</script>