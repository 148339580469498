<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-13 14:28:10
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-14 15:34:25
 * @FilePath: \chuwudemo\src\views\operationPage\replyP\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column :prop="item.props" :label="item.label" v-for="(item, index) in clodata" :key="index">
      </el-table-column>
      <el-table-column prop="props.tableData" label="图片">
        <template #default="{ row }">
          <img :src="row.path" alt="" style="width:50px;height:50px;">
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="状态">
        <template #default="{ row }">
          <el-switch v-model="row.status" class="ml-2"
            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" disabled :active-value="1"
            :inactive-value="2" />
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="updated_at" label="更新时间"></el-table-column>
      <el-table-column prop="props.tableData" label="操作">
        <template #default="{ row }">
          <el-button type="primary" @click="detailsClick(row.id)">详情</el-button>
          <el-button type="primary" @click="editClick(row.id)">修改</el-button>
          <el-button type="danger" @click="deleteClick(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue"
const props = defineProps({
  tableData: {
    type: Array,
    default: []
  },
})
const clodata = reactive([
  { props: "gift_name", label: "礼物名称" },
  { props: "bean", label: "爱豆" },
])
const emit = defineEmits(['editClick', 'deleteClick', 'handleSelectionChange', 'detailsClick'])
const detailsClick = (id) => {
  emit('detailsClick', id)
}
const editClick = (id) => {
  emit('editClick', id)
}
const handleSelectionChange = (e) => {
  emit('handleSelectionChange', e)
}
const deleteClick = (id) => {
  emit('deleteClick', id)
}
</script>