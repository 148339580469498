<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-21 16:07:18
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-26 16:29:07
 * @FilePath: \chuwudemo\src\views\userPage\permissionI\components\dialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div v-if="props.status == 0">
    <el-dialog v-model="dialogTableVisible" title="详情" width="60%">
      <el-table :data="props.detailData" stripe style="width: 100%">
        <el-table-column prop="props.detailData" label="ID">
          <template #default="{ row }">
            <span>
              {{ row.id }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="User_ID">
          <template #default="{ row }">
            <span>
              {{ row.user_id }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="状态">
          <template #default="{ row }">
            <el-switch
              v-model="row.status"
              class="ml-2"
              style="
                --el-switch-on-color: #13ce66;
                --el-switch-off-color: #ff4949;
              "
              :active-value="1"
              :inactive-value="0"
              disabled="true"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
  <div v-if="props.status == 1">
    <el-dialog v-model="dialogTableVisible" title="用户权限修改" width="500">
      <el-form :model="props.upData">
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-switch
            v-model="props.upData.status"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <el-form-item label="开始时间" :label-width="formLabelWidth">
          <el-date-picker
            v-model="props.upData.start_at"
            type="date"
            placeholder="请选择开始时间"
            style="width: 100%"
          />
        </el-form-item>
        <el-form-item label="结束时间" :label-width="formLabelWidth">
          <el-date-picker
            v-model="props.upData.end_at"
            type="date"
            placeholder="请选择结束时间"
            style="width: 100%"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quan">取消</el-button>
          <el-button type="primary" @click="qxShe"> 确定 </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue";
const dialogTableVisible = defineModel("dialogTableVisible");
const props = defineProps({
  detailData: {
    type: Array,
    default: [],
  },
  status: {
    type: Number,
    default: 0,
  },
  upData: {
    type: Array,
    default: [],
  },
});
const emit = defineEmits(["qxShe", "quan"]);
const qxShe = () => {
  emit("qxShe", props.upData);
};
const quan = () => {
  emit("quan");
};
</script>
