/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-14 14:20:12
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-20 09:47:25
 * @FilePath: \chuwudemo\src\http\allocation.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 11:00:05
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-19 16:00:31
 * @FilePath: \chuwu\chuwudemo\src\http\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import service from '@/utils/request'
// get请求用params,post请求用data
//导出api方法
export function giftList (params) {
  return service({
    url: 'setting/gift',
    method: 'get',
    params: params
  })
}
export function giftDetails (id) {
  return service({
    url: `setting/gift/${id}`,
    method: 'get',
  })
}
export function deleteGift (params) {
  return service({
    url: `setting/gift/batch`,
    method: 'delete',
    params: params
  })
}
export function createGift (data) {
  return service({
    url: 'setting/gift',
    method: 'post',
    data: data
  })
}
export function updataGift (params, id) {
  return service({
    url: `setting/gift/${id}`,
    method: 'put',
    params: params
  })
}


export function impressList (params) {
  return service({
    url: 'setting/impression-tag',
    method: 'get',
    params: params
  })
}
export function impressDetails (id) {
  return service({
    url: `setting/impression-tag/${id}`,
    method: 'get',
  })
}
export function deleteImpress (params) {
  return service({
    url: `setting/impression-tag/batch`,
    method: 'delete',
    params: params
  })
}
export function createImpress (data) {
  return service({
    url: 'setting/impression-tag',
    method: 'post',
    data: data
  })
}
export function updataImpress (params, id) {
  return service({
    url: `setting/impression-tag/${id}`,
    method: 'put',
    params: params
  })
}


export function carouselList (params) {
  return service({
    url: 'setting/placards',
    method: 'get',
    params: params
  })
}
export function carouselDetails (id) {
  return service({
    url: `setting/placards/${id}`,
    method: 'get',
  })
}
export function deleteCarousel (id) {
  return service({
    url: `setting/placards/${id}`,
    method: 'delete',
  })
}
export function createCarousel (data) {
  return service({
    url: 'setting/placards',
    method: 'post',
    data: data
  })
}
export function updataCarousel (params, id) {
  return service({
    url: `setting/placards/${id}`,
    method: 'put',
    params: params
  })
}
export function displayCarousel (id) {
  return service({
    url: `setting/placards/display/${id}`,
    method: 'get',
  })
}
export function undisplayCarousel (id) {
  return service({
    url: `setting/placards/undisplay/${id}`,
    method: 'get',
  })
}



export function rechargeList (params) {
  return service({
    url: 'setting/recharge',
    method: 'get',
    params: params
  })
}
export function rechargeDetails (id) {
  return service({
    url: `setting/recharge/${id}`,
    method: 'get',
  })
}
export function deleteRecharge (id) {
  return service({
    url: `setting/recharge/${id}`,
    method: 'delete',
  })
}
export function createRecharge (data) {
  return service({
    url: 'setting/recharge',
    method: 'post',
    data: data
  })
}
export function updataRecharge (params, id) {
  return service({
    url: `setting/recharge/${id}`,
    method: 'put',
    params: params
  })
}

export function deployList (params) {
  return service({
    url: 'setting/setting',
    method: 'get',
    params: params
  })
}
export function deployDetails (id) {
  return service({
    url: `setting/setting/${id}`,
    method: 'get',
  })
}
export function deleteDeploy (id) {
  return service({
    url: `setting/setting/${id}`,
    method: 'delete',
  })
}
export function createDeploy (data) {
  return service({
    url: 'setting/setting',
    method: 'post',
    data: data
  })
}
export function updataDeploy (params, id) {
  return service({
    url: `setting/setting/${id}`,
    method: 'put',
    params: params
  })
}



export function versionList (params) {
  return service({
    url: 'setting/version',
    method: 'get',
    params: params
  })
}
export function versionDetails (id) {
  return service({
    url: `setting/version/${id}`,
    method: 'get',
  })
}
export function createVersion (data) {
  return service({
    url: 'setting/version',
    method: 'post',
    data: data
  })
}
export function updataVersion (params, id) {
  return service({
    url: `setting/version/${id}`,
    method: 'put',
    params: params
  })
}

export function agreementList (params) {
  return service({
    url: 'setting/agreement',
    method: 'get',
    params: params
  })
}
export function agreementDetails (id) {
  return service({
    url: `setting/agreement/${id}`,
    method: 'get',
  })
}
export function createAgreement (data) {
  return service({
    url: 'setting/agreement',
    method: 'post',
    data: data
  })
}
export function updataAgreement (params, id) {
  return service({
    url: `setting/agreement/${id}`,
    method: 'put',
    params: params
  })
}




export function reviewsList (params) {
  return service({
    url: 'setting/apple_reviews',
    method: 'get',
    params: params
  })
}
export function reviewsDetails (id) {
  return service({
    url: `setting/apple_reviews/${id}`,
    method: 'get',
  })
}
export function deleteReviews (id) {
  return service({
    url: `setting/apple_reviews/${id}`,
    method: 'delete',
  })
}
export function createReviews (data) {
  return service({
    url: 'setting/apple_reviews',
    method: 'post',
    data: data
  })
}
export function updataReviews (params, id) {
  return service({
    url: `setting/apple_reviews/${id}`,
    method: 'put',
    params: params
  })
}