/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-10 09:36:57
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-11 14:52:56
 * @FilePath: \demo_ele\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue';
import ElementPlus from 'element-plus'
import router from './router/index.js'
import 'element-plus/dist/index.css'
import store from "./store/index.js"
import App from './App.vue'
import axios from 'axios'
import pinia from './store'  //引入
import throttleDirective from '@/http/throttle' // 注册全局指令

// import pinia from './store'  //引入
const app = createApp(App)
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
app.use(router).use(ElementPlus).use(store).use(pinia).mount('#app');
app.config.globalProperties.$http = axios;
app.directive('throttle', throttleDirective)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

