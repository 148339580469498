/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 11:00:05
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-11 13:41:58
 * @FilePath: \chuwu\chuwudemo\src\http\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import service from '@/utils/request'
// get请求用params,post请求用data
//导出api方法
export function basicsList () {
  return service({
    url: '/statistic', //请求的路径 登录
    method: 'get',
  })
}