<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-12 09:45:06
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-27 16:55:12
 * @FilePath: \chuwudemo\src\views\userPage\components\dialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div v-if="props.status == 0">
    <el-dialog v-model="dialogTableVisible" title="详情" width="60%">
      <div>
        <el-table :data="props.detailData">
          <el-table-column prop="headimgurl" label="头像">
            <template #default="{ row }">
              <span v-if="row != null">
                <img
                  :src="row.headimgurl"
                  alt=""
                  style="width: 50px; height: 50px"
                />
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            v-for="(item, index) in cloData"
            :key="index"
          >
          </el-table-column>
          <el-table-column prop="sex" label="性别">
            <template #default="{ row }">
              <span v-if="row != null">
                {{ fn[row.sex] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="is_real_person" label="是否真人(商家)">
            <template #default="{ row }">
              <span v-if="row != null">
                <el-switch
                  v-model="row.is_real_person"
                  disabled="true"
                  class="ml-2"
                  style="
                    --el-switch-on-color: #13ce66;
                    --el-switch-off-color: #ff4949;
                  "
                  :active-value="1"
                  :inactive-value="2"
                ></el-switch>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="is_high_quality" label="是否优质用户">
            <template #default="{ row }">
              <el-switch
                v-model="row.is_high_quality"
                disabled="true"
                class="ml-2"
                style="
                  --el-switch-on-color: #13ce66;
                  --el-switch-off-color: #ff4949;
                "
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="is_online" label="是否在线">
            <template #default="{ row }">
              <el-switch
                v-model="row.is_online"
                disabled="true"
                class="ml-2"
                style="
                  --el-switch-on-color: #13ce66;
                  --el-switch-off-color: #ff4949;
                "
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="is_freeze" label="是否冻结">
            <template #default="{ row }">
              <el-switch
                v-model="row.is_freeze"
                disabled="true"
                class="ml-2"
                style="
                  --el-switch-on-color: #13ce66;
                  --el-switch-off-color: #ff4949;
                "
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="is_ban" label="是否封禁">
            <template #default="{ row }">
              <el-switch
                v-model="row.is_ban"
                disabled="true"
                class="ml-2"
                style="
                  --el-switch-on-color: #13ce66;
                  --el-switch-off-color: #ff4949;
                "
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
  <div v-if="props.status == 1">
    <el-dialog v-model="dialogTableVisible" title="修改" width="500">
      <el-form :model="props.upData" :rules="rules" ref="ruleFormRef">
        <el-form-item label="昵称" :label-width="formLabelWidth" prop="name">
          <el-input
            v-model="props.upData.name"
            autocomplete="off"
            placeholder="请输入昵称"
          />
        </el-form-item>
        <el-form-item
          label="签名"
          :label-width="formLabelWidth"
          prop="signature"
        >
          <el-input
            v-model="props.upData.signature"
            autocomplete="off"
            placeholder="请输入签名"
          />
        </el-form-item>
        <el-form-item label="是否冻结" :label-width="formLabelWidth">
          <el-switch
            v-model="props.upData.is_freeze"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <el-form-item label="是否封禁" :label-width="formLabelWidth">
          <el-switch
            v-model="props.upData.is_ban"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <el-form-item label="是否优质商家" :label-width="formLabelWidth">
          <el-switch
            v-model="props.upData.is_high_quality"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="sheHe(ruleFormRef)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <div v-if="props.status == 2">
    <el-drawer
      v-model="dialogTableVisible"
      size="45%"
      direction="rtl"
      title="用户资产详情"
      :before-close="handleClose"
    >
      <h1>当前用户:{{ props.nameS }}</h1>
      <div class="tss">
        <h1>资产统计</h1>
        <el-table :data="props.assetData" striped>
          <el-table-column property="props.assetData" label="累计资产">
            <template #default="{ row }">
              <span class="zc">{{ row.asset.total }}</span>
            </template>
          </el-table-column>
          <el-table-column property="props.assetData" label="余额">
            <template #default="{ row }">
              <span class="zc">{{ row.asset.balance }}</span>
            </template>
          </el-table-column>
          <el-table-column property="props.assetData" label="爱豆">
            <template #default="{ row }">
              <span class="zc">{{ row.asset.bean }}</span>
            </template>
          </el-table-column>
          <el-table-column property="props.assetData" label="累计提现">
            <template #default="{ row }">
              <span class="zc">{{ row.asset.withdraw }}</span>
            </template>
          </el-table-column>
          <el-table-column property="props.assetData" label="未确认收货金额">
            <template #default="{ row }">
              <span class="zc">{{ row.asset.wait }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tss">
        <h1>销售统计</h1>
        <el-table :data="props.assetData" striped>
          <el-table-column property="props.assetData" label="商品出售总额">
            <template #default="{ row }">
              <span class="zc">{{ row.sale.goods }}</span>
            </template>
          </el-table-column>
          <el-table-column
            property="props.assetData"
            label="相册出售总额(爱豆)"
          >
            <template #default="{ row }">
              <span class="zc">{{ row.sale.album }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tss">
        <h1>爱豆统计</h1>
        <el-table :data="props.assetData" striped>
          <el-table-column property="props.assetData" label="充值统计">
            <template #default="{ row }">
              <span class="zc">{{ row.bean.recharge }}</span>
            </template>
          </el-table-column>
          <el-table-column property="props.assetData" label="兑换总额">
            <template #default="{ row }">
              <span class="zc">{{ row.sale.exchange }}</span>
            </template>
          </el-table-column>
          <el-table-column
            property="props.exchange_real"
            label="兑换实际到账总额"
          >
            <template #default="{ row }">
              <span class="zc">{{ row.sale.goods }}</span>
            </template>
          </el-table-column>
          <el-table-column property="props.exchange_fee" label="兑换手续费">
            <template #default="{ row }">
              <span class="zc">{{ row.sale.album }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tss">
        <h1>提现</h1>
        <el-table :data="props.assetData" striped>
          <el-table-column property="props.assetData" label="提现总额">
            <template #default="{ row }">
              <span class="zc">{{ row.withdraw.withdraw }}</span>
            </template>
          </el-table-column>
          <el-table-column property="props.assetData" label="实际到账总额">
            <template #default="{ row }">
              <span class="zc">{{ row.withdraw.withdraw_real }}</span>
            </template>
          </el-table-column>
          <el-table-column property="props.exchange_real" label="手续费(利润)">
            <template #default="{ row }">
              <span class="zc">{{ row.withdraw.withdraw_fee }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
  <div></div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue";
const dialogTableVisible = defineModel("dialogTableVisible");
import { ElMessage, ElMessageBox } from "element-plus";
import {} from "@/http/user.js";
const fn = reactive({ 0: "未知", 1: "男", 2: "女" });

const weId = ref();


const ruleFormRef = ref();
const rules = reactive({
  name: [{ required: true, message: "请输入昵称", trigger: "change" }],
  signature: [{ required: true, message: "请输入签名", trigger: "change" }],
});
const cloData = reactive([
  { prop: "name", label: "昵称" },
  { prop: "phone_number", label: "手机号" },
  { prop: "user_id", label: "用户ID" },
  { prop: "platform", label: "平台" },
  { prop: "uuid_type", label: "用户平台" },
  { prop: "location", label: "位置" },
  { prop: "signature", label: "签名" },
  // { prop: "created_start", label: "注册实际-开始" },
  // { prop: "created_end", label: "注册实际-结束" },
]); //列表的头
const props = defineProps({
  detailData: {
    type: Array,
    default: [],
  },
  status: {
    type: Number,
    default: 0,
  },
  upData: {
    type: Object,
    default: {},
  },
  assetData: {
    type: Array,
    default: [],
  },
  nameS: {
    type: String,
    default: "",
  },
  PermissionList: {
    type: Array,
    default: [],
  },
  form: {
    type: Array,
    default: [],
  },
});
const emit = defineEmits(["sheHe", "qxShe"]);
const quXiao = () => {
  emit("quXiao");
};

const quan = () => {
  quanXian.value = false;
};

const sheHe = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log("submit!");
      emit("sheHe", props.upData);
    } else {
      console.log("error submit!", fields);
    }
  });
};



</script>
<style lang="scss" scoped>
.tss {
  border: 2px dashed skyblue;
  margin-top: 40px;
  height: 200px;
}

.zc {
  font-size: 18px;
  font-weight: bold;
}
</style>
