<template>
  <div v-if="props.status == 4">
    <el-dialog v-model="dialogTableVisible" title="详情" width="60%">
      <div style="margin-top: 20px">
        <el-table :data="props.PermissionList" striped>
          <el-table-column prop="props.PermissionList" label="权限昵称">
            <template #default="{ row }">
              {{ ro[row.name] }}
            </template>
          </el-table-column>
          <el-table-column prop="props.PermissionList" label="状态">
            <template #default="{ row }">
              <el-switch
                v-model="row.status"
                class="ml-2"
                style="
                  --el-switch-on-color: #13ce66;
                  --el-switch-off-color: #ff4949;
                "
                :active-value="1"
                :inactive-value="0"
                @change="changSelect(row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="props.PermissionList" label="开始时间">
            <template #default="{ row }">
              {{ row.start_at }}
            </template>
          </el-table-column>
          <el-table-column prop="props.PermissionList" label="结束时间">
            <template #default="{ row }">
              {{ row.end_at }}
            </template>
          </el-table-column>
          <el-table-column prop="props.PermissionList" label="操作">
            <template #default="{ row }">
              <el-button type="primary" @click="jurClick(row.id)"
                >详情</el-button
              >
              <el-button type="primary" @click="upClick(row)">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
  <div v-if="props.qxStatus == 0">
    <el-dialog v-model="quanXian" title="权限详情" width="60%">
      <el-table :data="quanXianList" striped>
        <el-table-column prop="quanXianList" label="权限昵称">
          <template #default="{ row }">
            {{ ro[row.name] }}
          </template>
        </el-table-column>
        <el-table-column prop="quanXianList" label="状态">
          <template #default="{ row }">
            <el-switch
              v-model="row.status"
              class="ml-2"
              style="
                --el-switch-on-color: #13ce66;
                --el-switch-off-color: #ff4949;
              "
              :active-value="1"
              :inactive-value="0"
              disabled="true"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="start_at" label="开始时间" />
        <el-table-column prop="end_at" label="结束时间" />
      </el-table>
    </el-dialog>
  </div>
  <div v-if="props.qxStatus == 1">
    <el-dialog v-model="quanXian" title="用户权限修改" width="500">
      <el-form :model="form">
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-switch
            v-model="props.form.status"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="0"
          />
        </el-form-item>
        <el-form-item label="开始时间" :label-width="formLabelWidth">
          <el-date-picker
            v-model="props.form.start_at"
            type="date"
            placeholder="请选择开始时间"
            style="width: 100%"
          />
        </el-form-item>
        <el-form-item label="结束时间" :label-width="formLabelWidth">
          <el-date-picker
            v-model="props.form.end_at"
            type="date"
            placeholder="请选择结束时间"
            style="width: 100%"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quan">取消</el-button>
          <el-button type="primary" @click="qxShe()"> 确定 </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue";
const quanXian = defineModel("quanXian");
const dialogTableVisible = defineModel("dialogTableVisible");

const ro = reactive({
  chat: "禁止聊天",
  comment: "禁止评论",
  reply: "禁止回复评论",
  withdraw: "禁止提现",
  post: "禁止发布动态",
  goods: "禁止发布商品",
  album: "禁止发布相册",
  index: "禁止首页展示",
});
const props = defineProps({
  PermissionList: {
    type: Array,
    default: [],
  },
  qxStatus: {
    type: Number,
    default: 0,
  },
  quanXianList: {
    type: Array,
    default: [],
  },
  form: {
    type: Array,
    default: [],
  },
  status: {
    type: Number,
    default: 0,
  },
});
const emit = defineEmits([
  "jurClick",
  "changSelect",
  "upClick",
  "qxShe",
  "quan",
]);
const jurClick = (id) => {
  emit("jurClick", id);
};
const changSelect = (id) => {
  emit("changSelect", id);
};
const upClick = (row) => {
  emit("upClick", row);
};
const qxShe = () => {
  emit("qxShe", props.form);
};
const quan = () => {
  emit("quan");
};
</script>
