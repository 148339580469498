/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 11:00:05
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-24 17:19:43
 * @FilePath: \chuwu\chuwudemo\src\http\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import service from '@/utils/request'
// get请求用params,post请求用data
//导出api方法
export function photoList (params) {
  return service({
    url: 'operate/album-report',
    method: 'get',
    params: params
  })
}
export function photoDetails (id) {
  return service({
    url: `operate/album-report/${id}`,
    method: 'get',
  })
}
export function deletePhoto (id) {
  return service({
    url: `operate/album-report/${id}`,
    method: 'delete',
  })
}
export function titleList (params) {
  return service({
    url: 'operate/ban',
    method: 'get',
    params: params
  })
}
export function titleDetails (id) {
  return service({
    url: `operate/ban/${id}`,
    method: 'get',
  })
}
export function deleteTitle (id) {
  return service({
    url: `operate/ban/${id}`,
    method: 'delete',
  })
}
export function remarkList (params) {
  return service({
    url: 'operate/comment',
    method: 'get',
    params: params
  })
}
export function remarkDetails (id) {
  return service({
    url: `operate/comment/${id}`,
    method: 'get',
  })
}
export function editRemark (params, id) {
  return service({
    url: `operate/comment/examine/${id}`,
    method: 'put',
    params: params
  })
}

export function immortalList (params) {
  return service({
    url: 'operate/describe-face-verify',
    method: 'get',
    params: params
  })
}
export function immortalDetails (id) {
  return service({
    url: `operate/describe-face-verify/${id}`,
    method: 'get',
  })
}
export function editImmortal (params, id) {
  return service({
    url: `operate/describe-face-verify/examine/${id}`,
    method: 'put',
    params: params
  })
}
export function cancelImmortal (id) {
  return service({
    url: `operate/describe-face-verify/un-real-rerson/${id}`,
    method: 'get',
  })
}
export function freezeList (params) {
  return service({
    url: 'operate/freeze',
    method: 'get',
    params: params
  })
}
export function freezeDetails (id) {
  return service({
    url: `operate/freeze/${id}`,
    method: 'get',
  })
}
export function chatList (params) {
  return service({
    url: 'operate/im',
    method: 'get',
    params: params
  })
}
export function removeChat (id) {
  return service({
    url: `operate/im/recall/${id}`,
    method: 'get',
  })
}
export function imageList (params) {
  return service({
    url: 'operate/photo',
    method: 'get',
    params: params
  })
}
export function editImage (params, id) {
  return service({
    url: `operate/photo/examine/${id}`,
    method: 'put',
    params: params,
  })
}
export function thawingList (params) {
  return service({
    url: 'operate/punish-recharge',
    method: 'get',
    params: params
  })
}
export function replyList (params) {
  return service({
    url: 'operate/reply',
    method: 'get',
    params: params
  })
}
export function editReply (params, id) {
  return service({
    url: `operate/reply/examine/${id}`,
    method: 'put',
    params: params,
  })
}
export function removeReply (params) {
  return service({
    url: 'operate/reply/batch',
    method: 'delete',
    params: params,
  })
}
export function dynamicList (params) {
  return service({
    url: 'operate/post-report',
    method: 'get',
    params: params
  })
}
export function editDynamic (params, id) {
  return service({
    url: `operate/post-report/feedback/${id}`,
    method: 'put',
    params: params,
  })
}
export function storeList (params) {
  return service({
    url: 'operate/shop-cover',
    method: 'get',
    params: params
  })
}
export function editStore (params, id) {
  return service({
    url: `operate/shop-cover/examine/${id}`,
    method: 'put',
    params: params,
  })
}
export function signatureList (params) {
  return service({
    url: 'operate/signature',
    method: 'get',
    params: params
  })
}
export function editSignature (params, id) {
  return service({
    url: `operate/signature/examine/${id}`,
    method: 'put',
    params: params,
  })
}
export function userList (params) {
  return service({
    url: 'operate/user-report',
    method: 'get',
    params: params
  })
}
export function editUser (params, id) {
  return service({
    url: `operate/user-report/feedback/${id}`,
    method: 'put',
    params: params
  })
}
export function deleteUser (params) {
  return service({
    url: `operate/user-report/batch`,
    method: 'delete',
    params: params
  })
}
export function checkList (params) {
  return service({
    url: 'operate/post',
    method: 'get',
    params: params
  })
}
export function editCheck (params, id) {
  return service({
    url: `operate/post/examine/${id}`,
    method: 'put',
    params: params
  })
}
export function deleteCheck (params) {
  return service({
    url: 'operate/post/batch',
    method: 'delete',
    params: params
  })
}
export function ipadList (params) {
  return service({
    url: 'operate/album-photo',
    method: 'get',
    params: params
  })
}
export function editIpad (params, id) {
  return service({
    url: `operate/album-photo/examine/${id}`,
    method: 'put',
    params: params
  })
}
export function deleteIpad (params) {
  return service({
    url: 'operate/album-photo/batch',
    method: 'delete',
    params: params
  })
}
