<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 10:41:00
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-26 09:36:16
 * @FilePath: \chuwudemo\src\components\commonaside.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 10:41:00
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-24 17:08:53
 * @FilePath: \chuwudemo\src\components\commonaside.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="aside">
    <!-- 菜单图标 -->
    <div class="menu">
      <span>出物社区平台</span>
    </div>
    <div class="aside-content">
      <el-menu
        :default-active="defaultActive"
        class="el-menu-vertical-demo"
        :collapse="isCollapse"
        :router="true"
        :unique-opened="true"
        background-color="#2a5298"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <el-menu-item index="/userIndex">
          <template #title>
            <el-icon>
              <Menu />
            </el-icon>
            <span>首页</span>
          </template>
        </el-menu-item>
        <el-sub-menu index="1">
          <template #title>
            <el-icon>
              <UserFilled />
            </el-icon>
            <span>用户</span>
          </template>
          <el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/guard">
                <el-icon>
                  <Notification />
                </el-icon>
                <span>守护</span>
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/user">
                <el-icon>
                  <DocumentCopy />
                </el-icon>
                <span>用户</span>
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/assets">
                <el-icon>
                  <Wallet />
                </el-icon>
                <span>资产</span>
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/idol">
                <el-icon>
                  <Money />
                </el-icon>
                <span>爱豆流水</span>
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/capital">
                <el-icon>
                  <CreditCard />
                </el-icon>
                <span>充值</span>
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/withdraw">
                <el-icon>
                  <Coin />
                </el-icon>
                <span>提现</span>
                <span class="yuan">
                  {{ navLists.withdraw }}
                </span>
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/exchange">
                <el-icon>
                  <Cpu />
                </el-icon>
                <span>爱豆兑换</span>
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/viporder">
                <el-icon>
                  <Star />
                </el-icon>
                <span>VIP订单</span>
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/permission">
                <el-icon>
                  <User />
                </el-icon>
                <span>用户权限</span>
              </el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/giftorder">
                <el-icon>
                  <ShoppingCartFull />
                </el-icon>
                <span>礼物订单</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="2">
          <template #title>
            <el-icon>
              <HomeFilled />
            </el-icon>
            <span>商品和交易</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/refund">
              <el-icon>
                <PieChart />
              </el-icon>
              <span>商品退款</span>
              <span class="yuan">
                {{ navLists.good_refund }}
              </span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/shop">
              <el-icon>
                <Grid />
              </el-icon>
              <span>商品管理</span>
              <span class="yuan">
                {{ navLists.good_examine }}
              </span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/order">
              <el-icon>
                <Place />
              </el-icon>
              <span>订单</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/shopImage">
              <el-icon>
                <PictureFilled />
              </el-icon>
              <span>商品图片</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="3">
          <template #title>
            <el-icon>
              <Promotion />
            </el-icon>
            <span>运营</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/photo">
              <el-icon>
                <Crop />
              </el-icon>
              <span>相册举报</span>
              <span class="yuan">
                {{ navLists.album_report }}
              </span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/title">
              <el-icon>
                <CircleClose />
              </el-icon>
              <span>封号</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/remark">
              <el-icon>
                <Finished />
              </el-icon>
              <span>评论审核</span>
              <span class="yuan">
                {{ navLists.comment_examine }}
              </span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/immortal">
              <el-icon>
                <Open />
              </el-icon>
              <span>真人验证</span>
              <span class="yuan">
                {{ navLists.describe_face_verifies }}
              </span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/freeze">
              <el-icon>
                <FolderDelete />
              </el-icon>
              <span>冻结</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/chat">
              <el-icon>
                <ChatDotSquare />
              </el-icon>
              <span>聊天记录</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/image">
              <el-icon>
                <Picture />
              </el-icon>
              <span>形象照审核</span>
              <span class="yuan">
                {{ navLists.photo }}
              </span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/thawing">
              <el-icon>
                <Sunny />
              </el-icon>
              <span>解冻保证金</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/reply">
              <el-icon>
                <Comment />
              </el-icon>
              <span>回复审核</span>
              <span class="yuan">
                {{ navLists.reply }}
              </span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/dynamic">
              <el-icon>
                <CircleCloseFilled />
              </el-icon>
              <span>动态举报</span>
              <span class="yuan">
                {{ navLists.post_report }}
              </span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/store">
              <el-icon>
                <Monitor />
              </el-icon>
              <span>铺面审核</span>
              <span class="yuan">
                {{ navLists.shop_cover }}
              </span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/signature">
              <el-icon>
                <DataBoard />
              </el-icon>
              <span>签名审核</span>
              <span class="yuan">
                {{ navLists.signature }}
              </span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/dubious">
              <el-icon>
                <PictureRounded />
              </el-icon>
              <span>可疑记录</span>
              <span class="yuan">
                {{ navLists.suspicious_chat }}
              </span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/userAud">
              <el-icon>
                <Iphone />
              </el-icon>
              <span>用户举报</span>
              <span class="yuan">
                {{ navLists.user_report_chat }}
              </span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/check">
              <el-icon>
                <FolderOpened />
              </el-icon>
              <span>动态审核</span>
              <span class="yuan">
                {{ navLists.post }}
              </span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/ipad">
              <el-icon>
                <Camera />
              </el-icon>
              <span>相册审核</span>
              <span class="yuan">
                {{ navLists.album_photo }}
              </span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="4">
          <template #title>
            <el-icon>
              <Tools />
            </el-icon>
            <span>配置</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/gift">
              <el-icon>
                <TakeawayBox />
              </el-icon>
              <span>礼物</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/impress">
              <el-icon>
                <Coordinate />
              </el-icon>
              <span>印象标签</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/carousel">
              <el-icon>
                <Film />
              </el-icon>
              <span>轮播</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/recharge">
              <el-icon>
                <Sell />
              </el-icon>
              <span>充值设置</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/deploy">
              <el-icon>
                <Orange />
              </el-icon>
              <span>配置</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/version">
              <el-icon>
                <Files />
              </el-icon>
              <span>版本</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/agreement">
              <el-icon>
                <Operation />
              </el-icon>
              <span>协议设置</span>
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/reviews">
              <el-icon>
                <Upload />
              </el-icon>
              <span>上架</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="5">
          <template #title>
            <el-icon>
              <Share />
            </el-icon>
            <span>统计</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/basics">
              <el-icon>
                <location />
              </el-icon>
              <span> 基础 </span>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
      </el-menu>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted, reactive } from "vue";
import { useRoute, onBeforeRouteUpdate } from "vue-router";
import { navList } from "@/http/user.js";
const isCollapse = ref(false);
const router = useRoute();
const defaultActive = ref("");
const curPath = ref();
const menuList = ref();
let navLists = reactive({});
watch(
  router,
  (value) => {
    defaultActive.value = value.path;
  },
  { immediate: true }
);
onMounted(() => {
  init();
  getRouter();
  getList();
});
const getList = () => {
  navList().then((res) => {
    sessionStorage.setItem("setData", JSON.stringify(res.data));
    navLists = JSON.parse(sessionStorage.getItem("setData"));
  });
};
const getRouter = () => {
  onBeforeRouteUpdate((to) => {
    curPath.path = to.path;
  });
};
const init = () => {
  curPath.value = router.fullPath;
  menuList.value = router.matched[0].children;
};
</script>
<style lang="scss" scoped>
.el-sub-menu .el-menu-item {
  height: 40px !important;
}

.el-menu-item.is-active {
  background: slateblue !important;
}

.aside {
  width: 100%;
  height: 100%;
}

.yuan {
  display: inline-block;
  margin-left: 20px;
  width: 20px;
  line-height: 20px;
  border-radius: 50%;
  background: #333;
  color: yellow;
  text-align: center;
}

.menu {
  width: 100%;
  line-height: 66px;
  color: black;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
}

.aside-content {
  background: #545c64;
}
</style>
