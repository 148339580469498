<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-12 11:03:41
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-12 15:17:10
 * @FilePath: \chuwudemo\src\views\operationPage\photoAlbum\components\dialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-dialog v-model="dialogTableVisible" title="详情" width="60%">
      <el-table :data="props.detailData">
        <el-table-column prop="props.detailData" label="举报人用户ID">
          <template #default="{ row }">
            <span>{{ row.user.id }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="举报人用户名">
          <template #default="{ row }">
            <span>{{ row.user.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="被举报人用户ID">
          <template #default="{ row }">
            <span v-if="row.report_user != null">{{ row.report_user.id }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="被举报人用户名">
          <template #default="{ row }">
            <span v-if="row.report_user != null">{{ row.report_user.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="图片">
          <template #default="{ row }">
            <img :src="row.album_photo.url" alt="" style="width:50px;height:50px;">
          </template>
        </el-table-column>
        <el-table-column prop="reason" label="原因"></el-table-column>
        <el-table-column prop="created_at" label="创建时间"></el-table-column>
        <el-table-column prop="updated_at" label="更新时间"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
const dialogTableVisible = defineModel('dialogTableVisible')
const props = defineProps({
  detailData: {
    type: Array,
    default: []
  },
})
</script>