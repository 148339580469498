<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-12 13:34:29
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-26 17:47:11
 * @FilePath: \chuwudemo\src\views\operationPage\title\titleTi.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-12 13:34:29
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-12 15:48:44
 * @FilePath: \chuwudemo\src\views\operationPage\title\titleTi.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="search">
      <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入ID"
        v-model="searchValue.user_id"
      ></el-input>
      <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入真实姓名"
        v-model="searchValue.cert_name"
      ></el-input>
      <el-button type="primary" style="margin-left: 10px" @click="searchClick"
        >搜索</el-button
      >
      <el-button type="primary" @click="restClick">重置查询条件</el-button>
    </div>
    <div class="content">
      <tableDate
        :tableData="tableData"
        @editClick="editClick"
        @detailsClick="detailsClick"
        @editsClick="editsClick"
        @peopleClick="peopleClick"
      >
      </tableDate>
    </div>
    <div>
      <pagination
        :painData="painData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
      </pagination>
    </div>
    <div>
      <diaLog
        :status="status"
        v-model:dialogTableVisible="dialogTableVisible"
        :detailData="detailData"
        @sheHe="sheHe"
        @quXiao="quXiao"
      >
      </diaLog>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  immortalList,
  immortalDetails,
  editImmortal,
  cancelImmortal,
} from "@/http/operation.js";
import { ElMessage, ElMessageBox } from "element-plus";
import tableDate from "./components/tableData.vue";
import search from "@/components/search.vue";
import pagination from "@/components/pagination.vue";
import diaLog from "./components/dialog";
const tableData = ref();
const detailData = ref();
const dialogTableVisible = ref(false);
const id = ref();
const status = ref(0);
const painData = reactive({
  total: 0, //一共多少条
  limit: 10, //一页多少条
  currentPage: 1, //第几页
});
const searchValue = reactive({
  user_id: "", //用户ID
  cert_name: "",
});
onMounted(() => {
  getList();
});
const searchClick = () => {
  const params = {
    cert_name: searchValue.cert_name,
    user_id: searchValue.user_id,
    page: painData.currentPage,
    limit: painData.limit,
  };
  immortalList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const restClick = () => {
  getList();
  Object.keys(searchValue).forEach((key) => {
    searchValue[key] = "";
  });
};
const getList = () => {
  const params = {
    page: painData.currentPage,
    limit: painData.limit,
  };
  immortalList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const detailsClick = (e) => {
  id.value = e;
  dialogTableVisible.value = true;
  getDetails();
  status.value = 0;
};
const getDetails = () => {
  immortalDetails(id.value)
    .then((res) => {
      detailData.value = [res.data];
    })
    .catch((err) => {});
};
const editClick = (e) => {
  //审核通过
  ElMessageBox.confirm("此操作将审核该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let params = {
        reviewing_status: "pass",
      };
      editImmortal(params, e)
        .then((res) => {
          if (res.code == -1) {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          } else {
            ElMessage({
              type: "success",
              message: "撤回成功",
            });
            getList();
          }
          getList();
        })
        .catch(() => {});
    })
    .catch(() => {});
};
const editsClick = (e) => {
  //审核拒绝
  status.value = 1;
  id.value = e;
  dialogTableVisible.value = true;
};
const quXiao = () => {
  dialogTableVisible.value = false;
};
const sheHe = (e) => {
  let params = {
    reviewing_status: "reject",
    reviewing_reason: e.reviewing_reason,
  };
  editImmortal(params, id.value)
    .then((res) => {
      if (res.code == -1) {
        ElMessage({
          type: "error",
          message: res.msg,
        });
      } else {
        ElMessage({
          type: "success",
          message: "撤回成功",
        });
        getList();
      }
      dialogTableVisible.value = false;
      e.reviewing_reason = "";
      getList();
    })
    .catch(() => {});
};
const peopleClick = (e) => {
  ElMessageBox.confirm("此操作将取消真人该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      cancelImmortal(e)
        .then((res) => {
          ElMessage({
            type: "success",
            message: "取消成功",
          });
          getList();
        })
        .catch(() => {
          ElMessage({
            type: "error",
            message: "取消失败",
          });
        });
    })
    .catch(() => {});
};
const handleSizeChange = (val) => {
  //上一页
  painData.currentPage = val;
  getList();
};
const handleCurrentChange = (val) => {
  //下一页
  painData.currentPage = val;
  getList();
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 20px;
}
.el-input {
  width: 200px !important;
  margin-right: 10px;
}
.el-form-item {
  margin-bottom: 0 !important;
}
</style>
