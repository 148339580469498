<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%" @selection-change="handleSelectionChange">
      <!-- <el-table-column type="selection" width="40"></el-table-column> -->
      <el-table-column :prop="item.prop" :label="item.label" v-for="(item, index) in clodata" :key="index">
      </el-table-column>
      <el-table-column prop="props.tableData" label="操作">
        <template #default="{ row }">
          <el-button type="primary" @click="detailsClick(row.id)">详情</el-button>
          <el-button type="primary" @click="editClick(row.id)">修改</el-button>
          <el-button type="danger" @click="deleteClick(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue"
const props = defineProps({
  tableData: {
    type: Array,
    default: []
  },
})
const clodata = reactive([
  { prop: "name", label: "商品名" },
  { prop: "apple_product_id", label: "苹果产品ID" },
  { prop: "money", label: "金额" },
  { prop: "bean_for_ios", label: "苹果可得爱豆" },
  { prop: "bean_for_android", label: "安卓可得爱豆" },
  { prop: "created_at", label: "创建时间" },
  { prop: "updated_at", label: "修改时间" },
])
const emit = defineEmits(['editClick', 'deleteClick', 'handleSelectionChange', 'detailsClick', 'changSwitch'])
const detailsClick = (id) => {
  emit('detailsClick', id)
}
const editClick = (id) => {
  emit('editClick', id)
}
const handleSelectionChange = (e) => {
  emit('handleSelectionChange', e)
}
const deleteClick = (id) => {
  emit('deleteClick', id)
}
const changSwitch = (e) => {
  emit('changSwitch', e)
}
</script>