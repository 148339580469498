/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 16:46:57
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-20 15:54:48
 * @FilePath: \chuwu\chuwudemo\src\router\user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import refund from "@/views/merchandise/refundD/refund"
import shop from "@/views/merchandise/shopP/shop"
import order from "@/views/merchandise/orderR/order"
import shopImage from "@/views/merchandise/shopImage/shopImage"
const merRouts = [
  {
    path: '/refund',
    name: '商品和交易',
    meta: { title: "商品和交易" },
    children: [
      {
        path: '/refund',
        name: '商品退款',
        component: refund,
      },
      {
        path: '/shop',
        name: '商品管理',
        component: shop,
      },
      {
        path: '/order',
        name: '订单',
        component: order,
      },
      {
        path: '/shopImage',
        name: '商品图片',
        component: shopImage,
      },
    ]
  },
]
export default merRouts
