<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-13 10:45:52
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-13 11:58:25
 * @FilePath: \chuwudemo\src\views\operationPage\chattingRecords\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%A
-->
<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%">
      <el-table-column prop="props.tableData" label="是否群聊">
        <template #default="{ row }">
          {{ fn[row.is_group] }}
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="商家/VIP">
        <template #default="{ row }">
          {{ fns[row.from_user.vip_level] }}
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="发送者ID">
        <template #default="{ row }">
          {{ row.from_user.id }}
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="发送者昵称">
        <template #default="{ row }">
          {{ row.from_user.name }}
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="发送内容">
        <template #default="{ row }">
          <span v-if="row.type == 'text'">
            {{ row.content }}
          </span>
          <span v-if="row.type == 'image'">
            <img :src="row.content" alt="" style="width: 50px;height:50px;" /> </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="接收者昵称">
        <template #default="{ row }">
          {{ row.to_user.name }}
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="接收者/群ID">
        <template #default="{ row }">
          {{ row.to_user.id }}
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="发送时间">
        <template #default="{ row }">
          {{ row.created_at }}
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="操作">
        <template #default="{ row }">
          <el-button type="danger" @click="removeClick(row.id)">撤回消息</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue"
const props = defineProps({
  tableData: {
    type: Array,
    default: []
  },
})
const fn = reactive({
  0: "单聊",
  1: "群聊",
})
const fns = reactive({
  1: "白银",
  2: "黄金",
})
const emit = defineEmits(['removeClick'])
const removeClick = (id) => {
  emit('removeClick', id)
}
</script>