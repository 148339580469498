<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-12 09:29:47
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-20 16:44:03
 * @FilePath: \chuwudemo\src\views\userPage\user\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 17:00:25
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-12 10:33:57
 * @FilePath: \chuwudemo\src\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%">
      <el-table-column prop="props.tableData" label="ID">
        <template #default="{ row }">
          <span>
            {{ row.id }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户ID">
        <template #default="{ row }">
          <span>
            {{ row.user.id }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户名">
        <template #default="{ row }">
          <span>
            {{ row.user.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="被守护者ID">
        <template #default="{ row }">
          <span>
            {{ row.protected_user.id }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="被守护者名">
        <template #default="{ row }">
          <span>
            {{ row.protected_user.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="商品ID">
        <template #default="{ row }">
          <span>
            {{ row.guard_product.id }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="商品名">
        <template #default="{ row }">
          <span>
            {{ row.guard_product.guard_name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="乐豆">
        <template #default="{ row }">
          <span>
            {{ row.guard_product.bean }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue";
const props = defineProps({
  tableData: {
    type: Array,
    default: [],
  },
});
const fn = reactive({ 0: "未知", 1: "男", 2: "女" });

const emit = defineEmits(["detailsClick"]);
const detailsClick = (id) => {
  emit("detailsClick", id);
};
</script>
<style lang="scss"></style>
