<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-12 11:03:41
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-13 11:56:23
 * @FilePath: \chuwudemo\src\views\operationPage\photoAlbum\components\dialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div v-if="props.status == 0">
    <el-dialog v-model="dialogTableVisible" title="详情" width="60%">
      <el-table :data="props.detailData">
        <el-table-column prop="props.detailData" label="昵称">
          <template #default="{ row }">
            <span v-if="row.user != null">{{ row.user.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="动态内容">
          <template #default="{ row }">
            <span v-if="row.post != null">{{ row.post.content }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="评论人ID">
          <template #default="{ row }">
            <span v-if="row.post != null">{{ row.user.id }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="评论人用户名">
          <template #default="{ row }">
            <span v-if="row.user != null">{{ row.user.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="内容">
          <template #default="{ row }">
            <span>{{ row.content }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="审核状态">
          <template #default="{ row }">
            <span>{{ fn[row.reviewing_status] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="审核意见">
          <template #default="{ row }">
            <span>{{ row.reviewing_reason }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
  <div v-if="props.status == 1">
    <el-dialog v-model="dialogTableVisible" title="审核拒绝必填项" width="40%">
      <el-form :model="form" :rules="rules" ref="ruleFormRef">
        <el-form-item label="审核意见" :label-width="formLabelWidth" prop="reviewing_reason">
          <el-input v-model="form.reviewing_reason" autocomplete="off" placeholder="请输入审核意见" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="sheHe(ruleFormRef)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
const dialogTableVisible = defineModel('dialogTableVisible')
const props = defineProps({
  detailData: {
    type: Array,
    default: []
  },
  status: {
    type: Number,
    default: 0
  },
})
const ruleFormRef = ref()
const form = reactive({
  reviewing_reason: ""
})
const rules = reactive({
  reviewing_reason: [
    { required: true, message: '请输入审核意见', trigger: 'change' },
  ],
})
const emit = defineEmits(['sheHe'])
const quXiao = () => {
  emit('quXiao')
}
const sheHe = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      emit('sheHe', form)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const fn = reactive({
  reviewing: "待审核",
  reject: "已拒绝",
  pass: "已通过",
})
</script>