<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-13 14:28:10
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-13 16:38:19
 * @FilePath: \chuwudemo\src\views\operationPage\replyP\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%">
      <el-table-column prop="props.tableData" label="ID">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="UserID">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="User">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="签名内容">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.content }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="审核状态">
        <template #default="{ row }">
          <span v-if="row != null">{{ fn[row.status] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="审核意见">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.reason }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="updated_at" label="修改时间"></el-table-column>
      <el-table-column prop="props.tableData" label="操作">
        <template #default="{ row }">
          <div v-if="row.status == 'reviewing'">
            <el-button type="primary" @click="editClick(row.id)"
              >通过</el-button
            >
            <el-button type="danger" @click="editsClick(row.id)"
              >拒绝</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue";
const props = defineProps({
  tableData: {
    type: Array,
    default: [],
  },
});
const fn = reactive({
  reviewing: "待审核",
  reject: "已拒绝",
  pass: "已通过",
});
const emit = defineEmits(["editClick", "editsClick"]);
const editClick = (id) => {
  emit("editClick", id);
};

const editsClick = (id) => {
  emit("editsClick", id);
};
</script>
