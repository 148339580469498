<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%">
      <el-table-column prop="props.tableData" label="昵称">
        <template #default="{ row }">
          <span>{{ row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column :prop="item.prop" :label="item.label" v-for="(item, index) in cloData" :key="index">
      </el-table-column>
      <el-table-column prop="props.tableData" label="操作">
        <template #default="{ row }">
          <el-button type="primary" @click="detailsClick(row.id)">详情</el-button>
          <el-button type="danger" @click="deleteClick(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue"
const props = defineProps({
  tableData: {
    type: Array,
    default: []
  },
})
const cloData = reactive([
  { prop: "user_id", label: "User_ID" },
  { prop: "created_at", label: "创建时间" },
  { prop: "updated_at", label: "更新时间" },
]) //列表的头
const emit = defineEmits(['detailsClick', 'deleteClick'])
const detailsClick = (id) => {
  emit('detailsClick', id)
}
const deleteClick = (id) => {
  emit('deleteClick', id)
}
</script>