<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%">
      <el-table-column prop="props.tableData" label="UserID">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="昵称">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="Order_No">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.order_no }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="类型">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.type }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="状态">
        <template #default="{ row }">
          <span v-if="row != null">{{ fn[row.status] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="支付金额">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.total_amount }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="updated_at" label="修改时间"></el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue"
const props = defineProps({
  tableData: {
    type: Array,
    default: []
  },
})
const fn = reactive({
  reviewing: "待审核",
  reject: "已拒绝",
  pass: "已通过",
  paid: "已支付"
})

</script>