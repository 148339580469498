/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 16:46:57
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-21 16:07:32
 * @FilePath: \chuwu\chuwudemo\src\router\user.js
import idol from "@/views/userPage/idolD/idol"
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import user from "@/views/userPage/user/userInfo"
import guard from "@/views/userPage/guardA/guard"
import assets from "@/views/userPage/assetE/assets"
import idol from "@/views/userPage/idolD/idol"
import capital from "@/views/userPage/capitalT/capital"
import withdraw from "@/views/userPage/withdrawD/withdraw"
import exchange from "@/views/userPage/exchangeC/exchange"
import viporder from "@/views/userPage/viporderO/viporder"
import giftorder from "@/views/userPage/giftorderO/giftorder"
import permission from "@/views/userPage/permissionI/permission"
const userRouts = [
  {
    path: '/user',
    name: '用户',
    meta: { title: "用户" },
    children: [
      {
        path: '/user',
        name: '用户',
        component: user,
      },
      {
        path: '/guard',
        name: '守护',
        component: guard,
      },
      {
        path: '/assets',
        name: '资产',
        component: assets,
      },
      {
        path: '/idol',
        name: '爱豆流水',
        component: idol,
      },
      {
        path: '/capital',
        name: '充值',
        component: capital,
      },
      {
        path: '/withdraw',
        name: '提现',
        component: withdraw,
      },
      {
        path: '/exchange',
        name: '爱豆兑换',
        component: exchange,
      },
      {
        path: '/viporder',
        name: 'VIP订单',
        component: viporder,
      },
      {
        path: '/giftorder',
        name: '礼物订单',
        component: giftorder,
      },
      {
        path: '/permission',
        name: '用户权限',
        component: permission,
      },
    ]
  },
]
export default userRouts

user