<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-13 14:28:10
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-28 10:13:46
 * @FilePath: \chuwudemo\src\views\operationPage\replyP\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table
      :data="props.tableData"
      stripe
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column type="selection" width="40"></el-table-column> -->
      <el-table-column prop="props.tableData" label="商品ID">
        <template #default="{ row }">
          <span>{{ row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户ID">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户名">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="产品介绍">
        <template #default="{ row }">
          <span>{{ row.content }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="商品首图">
        <template #default="{ row }">
          <span v-if="row.images != ''">
            <img
              :src="row.images[0].url"
              alt=""
              style="width: 70px; height: 70px"
            />
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="价格">
        <template #default="{ row }">
          <span>{{ row.price }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="可出售(不包含购买中)">
        <template #default="{ row }">
          <span>{{ row.count }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="props.tableData" label="购买中">
        <template #default="{ row }">
          <span>{{ row.locked_count }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="props.tableData" label="销量">
        <template #default="{ row }">
          <span>{{ row.sold_count }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="props.tableData" label="销量增加">
        <template #default="{ row }">
          <span>{{ row.fake_count }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="发货方式">
        <template #default="{ row }">
          <span>{{ mt[row.shipping_method] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="类别">
        <template #default="{ row }">
          <span>{{ ty[row.type] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="状态">
        <template #default="{ row }">
          <el-switch
            v-model="row.status"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="0"
            disabled
          />
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="审核状态">
        <template #default="{ row }">
          <span>{{ fn[row.reviewing_status] }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="props.tableData" label="操作(审核)" width="180px">
        <template #default="{ row }">
          <span> </span>
          <span v-if="row.reviewing_status == 'reviewing'">
            <el-button type="primary" @click="editClick(row.id)"
              >通过</el-button
            >
          </span>
          <el-button type="danger" @click="deleteClick(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue";
const props = defineProps({
  tableData: {
    type: Array,
    default: [],
  },
});
const ty = reactive({
  shop: "商城",
  chat: "撩她",
  top: "上衣",
  pants: "裤装",
  suit: "套装",
  shoes_and_socks: "鞋袜",
  care: "个护",
  specialty: "零食",
  organize: "收纳",
  others: "其他",
});
const mt = reactive({
  express: "快递",
  pickup: "自提",
  null: "其他",
});
const fn = reactive({
  reviewing: "待审核",
  reject: "已拒绝",
  pass: "已通过",
});
const emit = defineEmits([
  "editClick",
  "editsClick",
  "deleteClick",
  "handleSelectionChange",
]);
const editClick = (id) => {
  emit("editClick", id);
};
const handleSelectionChange = (e) => {
  emit("handleSelectionChange", e);
};
const editsClick = (id) => {
  emit("editsClick", id);
};
const deleteClick = (id) => {
  emit("deleteClick", id);
};
</script>
