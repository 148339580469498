<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%">
      <el-table-column prop="props.tableData" label="ID">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户ID">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户昵称">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="订单号">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.order_no }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="提现金额">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.money }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="实际到账金额">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.real_money }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="手续费">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.fee }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="状态">
        <template #default="{ row }">
          <span v-if="row != null">{{ fn[row.status] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="updated_at" label="修改时间"></el-table-column>
      <el-table-column prop="props.tableData" label="操作">
        <template #default="{ row }">
          <div v-if="row.status == 'pending'">
            <el-button type="primary" @click="editClick(row.id)">通过</el-button>
            <el-button type="danger" @click="editsClick(row.id)">拒绝</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue"
const props = defineProps({
  tableData: {
    type: Array,
    default: []
  },
})
const fn = reactive({
  pending: "待审核",
  rejected: "已拒绝",
  paid: "已通过"
})
const emit = defineEmits(['editClick', 'editsClick'])
const editClick = (e) => {
  emit('editClick', e)
}
const editsClick = (e) => {
  emit('editsClick', e)
}
</script>