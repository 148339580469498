<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-13 14:28:10
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-26 17:29:54
 * @FilePath: \chuwudemo\src\views\operationPage\replyP\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table
      :data="props.tableData"
      stripe
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column prop="props.tableData" label="用户ID">
        <template #default="{ row }">
          <span>{{ row.user.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户名">
        <template #default="{ row }">
          <span>{{ row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="图片">
        <template #default="{ row }">
          <span v-if="row.url != ''">
            <img :src="row.url" alt="" style="width: 50px; height: 50px" />
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="props.tableData" label="操作(审核)" width="180px">
        <template #default="{ row }">
          <el-button type="danger" @click="deleteClick(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue";
const props = defineProps({
  tableData: {
    type: Array,
    default: [],
  },
});

const emit = defineEmits([
  "editClick",
  "editsClick",
  "deleteClick",
  "handleSelectionChange",
]);
const editClick = (id) => {
  emit("editClick", id);
};
const handleSelectionChange = (e) => {
  emit("handleSelectionChange", e);
};
const editsClick = (id) => {
  emit("editsClick", id);
};
const deleteClick = (id) => {
  emit("deleteClick", id);
};
</script>
