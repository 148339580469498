import basics from "@/views/basicsPage/basics"
const basicsRouts = [
  {
    path: '/basics',
    name: '统计',
    meta: { title: "基础" },
    children: [
      {
        path: '/basics',
        name: '基础',
        component: basics,
      }
    ]
  },
]
export default basicsRouts

