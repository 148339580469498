<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 16:52:14
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-26 18:01:20
 * @FilePath: \chuwudemo\src\views\userPage\guard.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="search">
      <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入ID"
        v-model="searchValue.user_id"
      ></el-input>
      <el-button type="primary" style="margin-left: 10px" @click="searchClick"
        >搜索</el-button
      >
      <el-button type="primary" @click="restClick">重置查询条件</el-button>
    </div>
    <!-- 比如这个页面需要详情和删除按钮 -->
    <div class="content">
      <tableDate
        :cloData="cloData"
        :tableData="tableData"
        @detailsClick="detailsClick"
      ></tableDate>
    </div>
    <div>
      <pagination
        :painData="painData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
      </pagination>
    </div>
    <div>
      <diaLog
        v-model:dialogTableVisible="dialogTableVisible"
        :detailData="detailData"
      ></diaLog>
    </div>
  </div>
</template>
<script setup>
// 使用封装的表单做到所有页面只需要调这一个组件包括内部定义的按钮事件
import { ref, reactive, onMounted } from "vue";
import { freezeDetails, freezeList } from "@/http/operation.js";
import tableDate from "./components/tableData.vue";
import search from "@/components/search.vue";
import pagination from "@/components/pagination.vue";
import diaLog from "./components/dialog";
const tableData = ref();
const detailData = ref();
const dialogTableVisible = ref(false);
const id = ref();
const painData = reactive({
  total: 0, //一共多少条
  limit: 10, //一页多少条
  currentPage: 1, //第几页
});
const searchValue = reactive({
  user_id: "", //用户ID
});
onMounted(() => {
  getList();
});
const searchClick = () => {
  const params = {
    user_id: searchValue.user_id,
    page: painData.currentPage,
    limit: painData.limit,
  };
  freezeList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const restClick = () => {
  getList();
  Object.keys(searchValue).forEach((key) => {
    searchValue[key] = "";
  });
};
const detailsClick = (e) => {
  dialogTableVisible.value = true;
  id.value = e;
  getDetails();
};
const getDetails = () => {
  freezeDetails(id.value)
    .then((res) => {
      detailData.value = [res.data];
    })
    .catch((err) => {});
};
const getList = () => {
  const params = {
    page: painData.currentPage,
    limit: painData.limit,
  };
  freezeList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const handleSizeChange = (val) => {
  //上一页
  painData.currentPage = val;
  getList();
};
const handleCurrentChange = (val) => {
  //下一页
  painData.currentPage = val;
  getList();
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 20px;
}
.el-input {
  width: 200px !important;
  margin-right: 10px;
}
.el-form-item {
  margin-bottom: 0 !important;
}
</style>
