<template>
  <div v-if="props.status == 1">
    <el-dialog v-model="dialogTableVisible" title="详情" width="40%">
      <el-table :data="props.detailData" stripe style="width: 100%">
        <el-table-column prop="props.tableData" label="平台">
          <template #default="{ row }">
            <span>{{ row.platform }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.tableData" label="版本">
          <template #default="{ row }">
            <span>{{ row.version }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.tableData" label="用户ID,英文逗号隔开">
          <template #default="{ row }">
            <span>{{ row.user_ids }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间"></el-table-column>
        <el-table-column prop="updated_at" label="更新时间"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
  <div v-if="props.status == 2">
    <el-dialog v-model="dialogTableVisible" title="详情" width="40%">
      <el-form :model="form" :rules="rules" ref="ruleFormRef">
        <el-form-item label="平台" :label-width="formLabelWidth" prop="platform">
          <el-select v-model="form.platform" placeholder="请选择平台" size="large" style="width: 240px">
            <el-option label="安卓" value="android" />
            <el-option label="苹果" value="ios" />
          </el-select>
        </el-form-item>
        <el-form-item label="版本号" :label-width="formLabelWidth" prop="version">
          <el-input v-model="form.version" autocomplete="off" placeholder="请输入版本号" />
        </el-form-item>
        <el-form-item label="用户ID" :label-width="formLabelWidth" prop="user_ids">
          <el-input v-model="form.user_ids" autocomplete="off" placeholder="请输入用户ID,多个用英文逗号隔开" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="queDing(ruleFormRef)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <div v-if="props.status == 3">
    <el-dialog v-model="dialogTableVisible" title="修改" width="40%">
      <el-form :model="props.upData" :rules="ruless" ref="ruleFormRefs">
        <el-form-item label="平台" :label-width="formLabelWidth" prop="platform">
          <el-select v-model="props.upData.platform" placeholder="请选择平台" size="large" style="width: 240px">
            <el-option label="安卓" value="android" />
            <el-option label="苹果" value="ios" />
          </el-select>
        </el-form-item>
        <el-form-item label="版本号" :label-width="formLabelWidth" prop="version">
          <el-input v-model="props.upData.version" autocomplete="off" placeholder="请输入版本号" />
        </el-form-item>
        <el-form-item label="用户ID" :label-width="formLabelWidth" prop="user_ids">
          <el-input v-model="props.upData.user_ids" autocomplete="off" placeholder="请输入用户ID,多个用英文逗号隔开" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="upDing(ruleFormRefs)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
const dialogTableVisible = defineModel('dialogTableVisible')
const ruleFormRef = ref()
const ruleFormRefs = ref()
const formLabelWidth = ref(140)
const props = defineProps({
  status: {
    type: Number,
    default: 0
  },
  detailData: {
    type: Array,
    default: []
  },
  upData: {
    type: Object,
    default: {}
  }
})
const form = reactive({
  platform: "",
  version: "",
  user_ids: ""
})
const rules = reactive({
  platform: [
    { required: true, message: '请选择平台', trigger: 'blur' },
  ],
  version: [
    { required: true, message: '请输入版本号', trigger: 'change' },
  ],
  user_ids: [
    { required: true, message: '请输入用户ID,多个用英文逗号隔开', trigger: 'change' },
  ],
})
const ruless = reactive({
  platform: [
    { required: true, message: '请选择平台', trigger: 'blur' },
  ],
  version: [
    { required: true, message: '请输入版本号', trigger: 'change' },
  ],
  user_ids: [
    { required: true, message: '请输入用户ID,多个用英文逗号隔开', trigger: 'change' },
  ],
})
const emit = defineEmits(['sheHe', 'queDing', 'upDing'])
const quXiao = () => {
  emit('quXiao')
}
const upDing = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!',)
      emit('upDing', props.upData)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const queDing = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!', form)
      emit('queDing', form)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const sheHe = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      emit('sheHe', form)
    } else {
      console.log('error submit!', fields)
    }
  })
}

</script>