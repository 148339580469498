/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-10 15:32:21
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-28 17:47:17
 * @FilePath: \demo_ele\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import { ElMessage } from "element-plus";
import router from "@/router/index.js";
// const BASE_URL = 'http://192.168.2.137/'
const BASE_URL = "https://cwsq.sqya.cc/"
const url = BASE_URL + 'admin'
// 创建一个 axios 实例
const service = axios.create({
  baseURL: url, // 所有的请求地址前缀部分
  timeout: 5000, // 请求超时时间毫秒
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'

  },
})

// 添加请求拦截器
service.interceptors.request.use(
  config => {
    if (sessionStorage && sessionStorage.getItem('user-token')) {
      config.headers['Authorization'] = sessionStorage.getItem('user-token')// 添加token
    }
    return config
  }
)
const goLogin = () => {

}
// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data
    if (dataAxios.code == 100) {
      ElMessage({
        type: "info",
        message: "登录失效,请重新登录",
      });
      sessionStorage.clear();
      router.push({
        //路由跳转
        path: "/",
      });
    }
    return dataAxios
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    // console.log(error)
    return Promise.reject(error)
  }
)

export default service
