<template>
  <div>
    <div class="search">
      <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入ID"
        v-model="searchValue.user_id"
      ></el-input>
      <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入卖家ID"
        v-model="searchValue.seller_id"
      ></el-input>
      <el-button type="primary" style="margin-left: 10px" @click="searchClick"
        >搜索</el-button
      >
      <el-button type="primary" @click="restClick">重置查询条件</el-button>
      <el-form-item label="订单状态" style="margin-left: 5px; width: 200px">
        <el-select
          v-model="searchValue.is_completed"
          placeholder="请选择"
          style="width: 240px"
          @change="stClick"
        >
          <el-option label="已完成" value="1" />
          <el-option label="未完成" value="0" />
        </el-select>
      </el-form-item>
      <el-form-item label="支付类型" style="margin-left: 5px; width: 200px">
        <el-select
          v-model="searchValue.payment_type"
          placeholder="请选择"
          style="width: 240px"
          @change="stClick"
        >
          <el-option label="支付宝" value="alipay" />
          <el-option label="余额" value="balance" />
          <el-option label="微信" value="wechat" />
        </el-select>
      </el-form-item>
      <el-form-item label="状态" style="margin-left: 5px; width: 200px">
        <el-select
          v-model="searchValue.status"
          placeholder="请选择"
          style="width: 240px"
          @change="stClick"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="(item, index) in stData"
            :key="index"
          />
        </el-select>
      </el-form-item>
    </div>
    <div class="content">
      <div style="float: right">
        <!-- <el-button type="danger" @click="remove" :disabled="disabled">批量移除</el-button> -->
      </div>
      <!-- <div style="float:right;margin-right:10px;">
        <el-button type="primary" @click="create">创建</el-button>
      </div> -->
      <tableDate :tableData="tableData"> </tableDate>
    </div>
    <div>
      <pagination
        :painData="painData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
      </pagination>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import { orderList } from "@/http/merchadn.js";
import { ElMessage, ElMessageBox } from "element-plus";
import tableDate from "./components/tableData.vue";
import search from "@/components/search.vue";
import pagination from "@/components/pagination.vue";
const tableData = ref();
const painData = reactive({
  total: 0, //一共多少条
  limit: 10, //一页多少条
  currentPage: 1, //第几页
});
onMounted(() => {
  getList();
});
const stData = reactive([
  {
    value: "pending",
    label: "待处理",
  },
  {
    value: "paid",
    label: "已支付",
  },
  {
    value: "shipping",
    label: "已发货",
  },
  {
    value: "received",
    label: "已收货",
  },
  {
    value: "completed",
    label: "已完成",
  },
  {
    value: "refunding",
    label: "已申请退款",
  },
  {
    value: "refunded",
    label: "已退款",
  },
  {
    value: "rejected",
    label: "商家拒绝退款",
  },
  {
    value: "closed",
    label: "平台拒绝退款",
  },
  {
    value: "timeout",
    label: "已超时",
  },
  {
    value: "canceled",
    label: "已取消",
  },
]);
const searchValue = reactive({
  user_id: "", //用户ID
  is_completed: "",
  status: "",
  payment_type: "",
  seller_id: "",
});
const stClick = () => {
  const params = {
    status: searchValue.status,
    payment_type: searchValue.payment_type,
    is_completed: searchValue.is_completed,
    page: painData.currentPage,
    limit: painData.limit,
  };
  orderList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const searchClick = () => {
  const params = {
    is_completed: searchValue.is_completed,
    user_id: searchValue.user_id,
    status: searchValue.status,
    payment_type: searchValue.payment_type,
    seller_id: searchValue.seller_id,
    page: painData.currentPage,
    limit: painData.limit,
  };
  orderList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const restClick = () => {
  getList();
  Object.keys(searchValue).forEach((key) => {
    searchValue[key] = "";
  });
};
const getList = () => {
  const params = {
    page: painData.currentPage,
    limit: painData.limit,
  };
  orderList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const handleSizeChange = (val) => {
  //上一页
  painData.currentPage = val;
  getList();
};
const handleCurrentChange = (val) => {
  //下一页
  painData.currentPage = val;
  getList();
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 20px;
}
.el-input {
  width: 200px !important;
  margin-right: 10px;
}
.el-form-item {
  margin-bottom: 0 !important;
}
</style>
