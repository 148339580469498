<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%">
      <el-table-column prop="props.tableData" label="UserID">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="昵称">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="身份证姓名">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.cert_name
            }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="身份证号码">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.cert_no
            }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="人脸">
        <template #default="{ row }">
          <img
            :src="'https://cn-shanghai-aliyun-cloudauth-1623638324099842.oss-cn-shanghai.aliyuncs.com/verify/1623638324099842/' + row.certify_id + '_0.jpeg'"
            alt="" style="width: 50px;height:50px;">
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="视频" width="150px">
        <template #default="{ row }">
          <video id="video" width="150px" height="81px" controls>
            <source :src="row.video_url" type="video/mp4">
          </video>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="新视频" width="140px">
        <template #default="{ row }">
          <span v-if="row.video_path_next != null">
            <video id="video" width="140px" height="81px" controls>
              <source :src="row.video_path_next" type="video/mp4">
            </video>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="视频状态" width="100px">
        <template #default="{ row }">
          <span v-if="row != null">{{ fn[row.video_status]
            }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="Status">
        <template #default="{ row }">
          <span v-if="row != null">{{ fn[row.status]
            }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reason" label="拒绝原因"></el-table-column>
      <el-table-column prop=" created_at" label="创建时间"></el-table-column>
      <el-table-column prop="updated_at" label="修改时间"></el-table-column>
      <el-table-column prop="props.tableData" label="操作(1) 真人" width="200px">
        <template #default="{ row }">
          <el-button type="primary" @click="detailsClick(row.id)">详情</el-button>
          <el-button type="danger" @click="peopleClick(row.id)">取消真人</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="操作(2) 审核">
        <template #default="{ row }">
          <el-button type="primary" @click="editClick(row.id)">通过</el-button>
          <el-button type="danger" @click="editsClick(row.id)">拒绝</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue"
const props = defineProps({
  tableData: {
    type: Array,
    default: []
  },
})
const fn = reactive({
  reviewing: "待审核",
  reject: "已拒绝",
  pass: "已通过",
})
const emit = defineEmits(['editsClick', 'editsClick', 'peopleClick', 'editClick'])
const detailsClick = (id) => {
  emit('detailsClick', id)
}
const editsClick = (id) => {
  emit('editsClick', id)
}
const editClick = (id) => {
  emit('editClick', id)
}
const peopleClick = (id) => {
  emit('peopleClick', id)
}
</script>
<style lang="scss" scoped>
.el-button+.el-button {
  margin-left: 10px !important;
}
</style>