<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-12 09:29:04
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-11 18:15:47
 * @FilePath: \demo_ele\src\components\pagination.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div style="padding: 10px 0">
      <el-pagination :current-page="props.painData.currentPage" :page-size="props.painData.pageSize"
        page-sizes="[2, 5, 10, 20]" layout="total, prev, pager, next" :total="props.painData.total"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  painData: {
    type: Object,
    default: () => { },
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  }
})
const emit = defineEmits(['handleSizeChange', 'handleCurrentChange'])
const handleSizeChange = (val) => { //上一页
  emit('handleSizeChange', val)
}
const handleCurrentChange = (val) => { //下一页
  emit('handleCurrentChange', val)
}
</script>
<style lang="scss"></style>