<template>
  <div>
    <div class="search">
      <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入ID"
        v-model="searchValue.user_id"
      ></el-input>
      <el-button type="primary" style="margin-left: 10px" @click="searchClick"
        >搜索</el-button
      >
      <el-button type="primary" @click="restClick">重置查询条件</el-button>
      <el-form-item label="类型" style="margin-left: 5px; width: 200px">
        <el-select
          v-model="searchValue.type"
          placeholder="请选择"
          style="width: 240px"
          @change="isClick"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="(item, index) in ty"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="审核状态" style="margin-left: 5px; width: 200px">
        <el-select
          v-model="searchValue.is_pass"
          placeholder="请选择"
          style="width: 240px"
          @change="isClick"
        >
          <el-option label="已通过" value="1" />
          <el-option label="未通过" value="0" />
        </el-select>
      </el-form-item>
    </div>
    <div class="content">
      <div style="float: right">
        <!-- <el-button type="danger" @click="remove" :disabled="disabled">批量移除</el-button> -->
      </div>
      <tableDate
        :tableData="tableData"
        @editClick="editClick"
        @deleteClick="deleteClick"
      >
      </tableDate>
    </div>
    <div>
      <pagination
        :painData="painData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
      </pagination>
    </div>
    <div>
      <diaLog
        v-model:dialogTableVisible="dialogTableVisible"
        :detailData="detailData"
        @sheHe="sheHe"
        @quXiao="quXiao"
      >
      </diaLog>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import { shopList, upShop, deleteShop } from "@/http/merchadn.js";
import { ElMessage, ElMessageBox } from "element-plus";
import tableDate from "./components/tableData.vue";
import search from "@/components/search.vue";
import pagination from "@/components/pagination.vue";
import diaLog from "./components/dialog";
const tableData = ref();
const detailData = ref();
const dialogTableVisible = ref(false);
const id = ref();
const disabled = ref(true);
const list = ref();
const painData = reactive({
  total: 0, //一共多少条
  limit: 10, //一页多少条
  currentPage: 1, //第几页
});
const searchValue = reactive({
  user_id: "", //用户ID
  type: "",
  is_pass: "",
});
const ty = reactive([
  {
    value: "shop",
    label: "商城",
  },
  {
    value: "chat",
    label: "撩她",
  },
  {
    value: "top",
    label: "上衣",
  },
  {
    value: "pants",
    label: "裤装",
  },
  {
    value: "suit",
    label: "套装",
  },
  {
    value: "shoes_and_socks",
    label: "鞋袜",
  },
  {
    value: "care",
    label: "个护",
  },
  {
    value: "specialty",
    label: "套装",
  },
  {
    value: "零食",
    label: "套装",
  },
  {
    value: "organize",
    label: "收纳",
  },
  {
    value: "others",
    label: "其他",
  },
]);
onMounted(() => {
  getList();
});
const isClick = () => {
  const params = {
    type: searchValue.type,

    is_pass: searchValue.is_pass,
    page: painData.currentPage,
    limit: painData.limit,
  };
  shopList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const searchClick = () => {
  const params = {
    type: searchValue.type,
    is_pass: searchValue.is_pass,
    user_id: searchValue.user_id,
    page: painData.currentPage,
    limit: painData.limit,
  };
  shopList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const restClick = () => {
  getList();
  Object.keys(searchValue).forEach((key) => {
    searchValue[key] = "";
  });
};
const getList = () => {
  const params = {
    page: painData.currentPage,
    limit: painData.limit,
  };
  shopList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};

const editClick = (e) => {
  //审核通过
  ElMessageBox.confirm("此操作将审核该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      const params = {
        reviewing_status: "pass",
      };
      upShop(params, e)
        .then((res) => {
          if (res.code == -1) {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          } else {
            ElMessage({
              type: "success",
              message: "审核成功",
            });
            getList();
          }
        })
        .catch(() => {});
    })
    .catch(() => {});
};
const deleteClick = (e) => {
  //单个删除
  ElMessageBox.confirm("此操作将删除部分数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      deleteShop(e)
        .then((res) => {
          if (res.code == -1) {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          } else {
            ElMessage({
              type: "success",
              message: "删除成功",
            });
            getList();
          }
        })
        .catch(() => {});
    })
    .catch(() => {});
};
const handleSizeChange = (val) => {
  //上一页
  painData.currentPage = val;
  getList();
};
const handleCurrentChange = (val) => {
  //下一页
  painData.currentPage = val;
  getList();
};
const quXiao = () => {
  dialogTableVisible.value = false;
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 20px;
}
.el-input {
  width: 200px !important;
  margin-right: 10px;
}
.el-form-item {
  margin-bottom: 0 !important;
}
</style>
