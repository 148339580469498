<template>
  <div>
    <el-dialog v-model="dialogTableVisible" title="审核拒绝必填项" width="40%">
      <el-form :model="form" :rules="rules" ref="ruleFormRef">
        <el-form-item label="审核意见" :label-width="formLabelWidth" prop="reviewing_reason">
          <el-input v-model="form.reviewing_reason" autocomplete="off" placeholder="请输入审核意见" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="sheHe(ruleFormRef)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
const dialogTableVisible = defineModel('dialogTableVisible')
const ruleFormRef = ref()
const form = reactive({
  reviewing_reason: ""
})
const rules = reactive({
  reviewing_reason: [
    { required: true, message: '请输入审核意见', trigger: 'change' },
  ],
})
const emit = defineEmits(['sheHe', 'quXiao'])
const sheHe = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      emit('sheHe', form)
    } else {
      console.log('error submit!', fields)
    }
  })
}

</script>