<template>
  <div v-if="props.status == 1">
    <el-dialog v-model="dialogTableVisible" title="详情" width="60%">
      <el-table :data="props.detailData" stripe style="width: 100%">
        <el-table-column prop="props.detailData" label="平台">
          <template #default="{ row }">
            <span>{{ row.platform }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="名称">
          <template #default="{ row }">
            <span>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="数字版本号">
          <template #default="{ row }">
            <span>{{ row.version_code }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="升级地址">
          <template #default="{ row }">
            <span>{{ row.url }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="是否升级(强制)">
          <template #default="{ row }">
            <el-switch v-model="row.is_force" class="ml-2"
              style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" :active-value="1"
              :inactive-value="0" disabled />
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="应用包名">
          <template #default="{ row }">
            <span>{{ row.package_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="升级介绍">
          <template #default="{ row }">
            <span v-html="row.description"></span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间"></el-table-column>
        <el-table-column prop="updated_at" label="更新时间"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
  <div v-if="props.status == 2">
    <el-dialog v-model="dialogTableVisible" title="详情" width="40%">
      <el-form :model="form" :rules="rules" ref="ruleFormRef">
        <el-form-item label="应用包名" :label-width="formLabelWidth" prop="package_name">
          <el-input v-model="form.package_name" autocomplete="off" placeholder="请输入应用宝名" />
        </el-form-item>
        <el-form-item label="昵称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="升级介绍" :label-width="formLabelWidth" prop="description">
          <el-input v-model="form.description" autocomplete="off" placeholder="请输入升级介绍" />
        </el-form-item>
        <el-form-item label="数字版本号" :label-width="formLabelWidth" prop="version_code">
          <el-input v-model="form.version_code" autocomplete="off" placeholder="请输入数字版本号" />
        </el-form-item>
        <el-form-item label="升级地址" :label-width="formLabelWidth" prop="url">
          <el-input v-model="form.url" autocomplete="off" placeholder="请输入升级地址" />
        </el-form-item>
        <el-form-item label="选择平台" :label-width="formLabelWidth" prop="platform">
          <el-select v-model="form.platform" filterable placeholder="请选择平台" style="width: 240px">
            <el-option label="苹果" value="ios" />
            <el-option label="安卓" value="Android" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否强制更新" :label-width="formLabelWidth" prop="is_force">
          <el-switch v-model="form.is_force" class="ml-2"
            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" :active-value="1"
            :inactive-value="0" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="queDing(ruleFormRef)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <div v-if="props.status == 3">
    <el-dialog v-model="dialogTableVisible" title="修改" width="40%">
      <el-form :model="props.upData" :rules="ruless" ref="ruleFormRefs">
        <el-form-item label="应用包名" :label-width="formLabelWidth" prop="package_name">
          <el-input v-model="props.upData.package_name" autocomplete="off" placeholder="请输入应用宝名" />
        </el-form-item>
        <el-form-item label="昵称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="props.upData.name" autocomplete="off" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="升级介绍" :label-width="formLabelWidth" prop="description">
          <el-input v-model="props.upData.description" autocomplete="off" placeholder="请输入升级介绍" />
        </el-form-item>
        <el-form-item label="数字版本号" :label-width="formLabelWidth" prop="version_code">
          <el-input v-model="props.upData.version_code" autocomplete="off" placeholder="请输入数字版本号" />
        </el-form-item>
        <el-form-item label="升级地址" :label-width="formLabelWidth" prop="url">
          <el-input v-model="props.upData.url" autocomplete="off" placeholder="请输入升级地址" />
        </el-form-item>
        <el-form-item label="选择平台" :label-width="formLabelWidth" prop="platform">
          <el-select v-model="props.upData.platform" filterable placeholder="请选择平台" style="width: 240px">
            <el-option label="苹果" value="ios" />
            <el-option label="安卓" value="Android" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否强制更新" :label-width="formLabelWidth" prop="is_force">
          <el-switch v-model="props.upData.is_force" class="ml-2"
            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" :active-value="1"
            :inactive-value="0" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="upDing(ruleFormRefs)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
const dialogTableVisible = defineModel('dialogTableVisible')
const ruleFormRef = ref()
const ruleFormRefs = ref()
const formLabelWidth = ref(140)
const props = defineProps({
  status: {
    type: Number,
    default: 0
  },
  detailData: {
    type: Array,
    default: []
  },
  upData: {
    type: Object,
    default: {}
  }
})
const form = reactive({
  package_name: "",
  name: "",
  description: "",
  url: "",
  version_code: "",
  platform: "",
  is_force: 0,
})
const rules = reactive({
  package_name: [
    { required: true, message: '请输入应用包名', trigger: 'change' },
  ],
  name: [
    { required: true, message: '请输入名称', trigger: 'change' },
  ],
  description: [
    { required: true, message: '请输入升级介绍', trigger: 'change' },
  ],
  version_code: [
    { required: true, message: '请输入数字版本号', trigger: 'change' },
  ],
  url: [
    { required: true, message: '请输入升级地址', trigger: 'change' },
  ],
  platform: [
    { required: true, message: '请选择平台', trigger: 'blur' },
  ],
  is_force: [
    { required: true, message: '是否强制升级', trigger: 'blur' },
  ],
})
const ruless = reactive({
  title: [
    { required: true, message: '请输入标题', trigger: 'change' },
  ],
  content: [
    { required: true, message: '请输入内容', trigger: 'change' },
  ],
  is_display: [
    { required: true, message: '请选择是否显示', trigger: 'change' },
  ],
})
const emit = defineEmits(['sheHe', 'queDing', 'upDing'])
const quXiao = () => {
  emit('quXiao')
}
const upDing = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!',)
      emit('upDing', props.upData)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const queDing = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!', form)
      emit('queDing', form)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const sheHe = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      emit('sheHe', form)
    } else {
      console.log('error submit!', fields)
    }
  })
}

</script>