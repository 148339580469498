<template>
  <div v-if="props.status == 1">
    <el-dialog v-model="dialogTableVisible" title="详情" width="40%">
      <el-table :data="props.detailData" stripe style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column prop="props.detailData" label="KEY">
          <template #default="{ row }">
            <span>{{ row.key }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="NAME">
          <template #default="{ row }">
            <span>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="VALUE">
          <template #default="{ row }">
            <span>{{ row.value }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间"></el-table-column>
        <el-table-column prop="updated_at" label="更新时间"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
  <div v-if="props.status == 2">
    <el-dialog v-model="dialogTableVisible" title="详情" width="40%">
      <el-form :model="form" :rules="rules" ref="ruleFormRef">
        <el-form-item label="KEY" :label-width="formLabelWidth" prop="key">
          <el-input v-model="form.key" autocomplete="off" placeholder="请输入key值" />
        </el-form-item>
        <el-form-item label="昵称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off" placeholder="请输入昵称" />
        </el-form-item>
        <el-form-item label="Value值" :label-width="formLabelWidth" prop="value">
          <el-input v-model="form.value" autocomplete="off" placeholder="请选择value值" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="queDing(ruleFormRef)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <div v-if="props.status == 3">
    <el-dialog v-model="dialogTableVisible" title="修改" width="40%">
      <el-form :model="props.upData" :rules="ruless" ref="ruleFormRefs">
        <el-form-item label="KEY" :label-width="formLabelWidth" prop="key">
          <el-input v-model="props.upData.key" autocomplete="off" placeholder="请输入key值" />
        </el-form-item>
        <el-form-item label="昵称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="props.upData.name" autocomplete="off" placeholder="请输入昵称" />
        </el-form-item>
        <el-form-item label="Value值" :label-width="formLabelWidth" prop="value">
          <el-input v-model="props.upData.value" autocomplete="off" placeholder="请选择value值" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="upDing(ruleFormRefs)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
const dialogTableVisible = defineModel('dialogTableVisible')
const ruleFormRef = ref()
const ruleFormRefs = ref()
const formLabelWidth = ref(140)
const props = defineProps({
  status: {
    type: Number,
    default: 0
  },
  detailData: {
    type: Array,
    default: []
  },
  upData: {
    type: Object,
    default: {}
  }
})
const form = reactive({
  key: "",
  name: "",
  value: ""
})
const rules = reactive({
  key: [
    { required: true, message: '请输入key值', trigger: 'change' },
  ],
  name: [
    { required: true, message: '请输入名称', trigger: 'change' },
  ],
  value: [
    { required: true, message: '请选择value值', trigger: 'change' },
  ],
})
const ruless = reactive({
  key: [
    { required: true, message: '请输入key值', trigger: 'change' },
  ],
  name: [
    { required: true, message: '请输入名称', trigger: 'change' },
  ],
  value: [
    { required: true, message: '请选择value值', trigger: 'change' },
  ],
})
const emit = defineEmits(['sheHe', 'queDing', 'upDing'])
const quXiao = () => {
  emit('quXiao')
}
const upDing = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!',)
      emit('upDing', props.upData)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const queDing = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!', form)
      emit('queDing', form)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const sheHe = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      emit('sheHe', form)
    } else {
      console.log('error submit!', fields)
    }
  })
}

</script>