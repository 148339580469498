<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-21 14:34:44
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-26 15:14:38
 * @FilePath: \chuwudemo\src\views\userPage\viporderO\viporder.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="search">
      <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入用户ID"
        v-model="searchValue.user_id"
      ></el-input>
      <el-button type="primary" style="margin-left: 10px" @click="searchClick"
        >搜索</el-button
      >
      <el-button type="primary" @click="restClick">重置查询条件</el-button>
      <el-form-item label="会员类型" style="margin-left: 5px; width: 200px">
        <el-select
          v-model="searchValue.name"
          placeholder="请选择"
          style="width: 240px"
          @change="naClick"
        >
          <el-option label="黄金会员" value="gold" />
          <el-option label="白银会员" value="silver " />
        </el-select>
      </el-form-item>
    </div>
    <!-- 比如这个页面需要详情和删除按钮 -->
    <div class="content">
      <tableDate :tableData="tableData"></tableDate>
    </div>
    <div>
      <pagination
        :painData="painData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
      </pagination>
    </div>
  </div>
</template>
<script setup>
// 使用封装的表单做到所有页面只需要调这一个组件包括内部定义的按钮事件
import { ref, reactive, onMounted } from "vue";
import { viporderList } from "@/http/user.js";
import tableDate from "./components/tableData.vue";
import search from "@/components/search.vue";
import pagination from "@/components/pagination.vue";
const tableData = ref();
const searchValue = reactive({
  user_id: "", //用户ID
  name: "", //类型
});
const painData = reactive({
  total: 0, //一共多少条
  limit: 10, //一页多少条
  currentPage: 1, //第几页
});
onMounted(() => {
  getList();
});
const naClick = () => {
  const params = {
    name: searchValue.name,
    page: painData.currentPage,
    limit: painData.limit,
  };
  viporderList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const searchClick = () => {
  const params = {
    name: searchValue.name,
    user_id: searchValue.user_id,
    page: painData.currentPage,
    limit: painData.limit,
  };
  viporderList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const restClick = () => {
  getList();
  Object.keys(searchValue).forEach((key) => {
    searchValue[key] = "";
  });
};
const getList = () => {
  const params = {
    page: painData.currentPage,
    limit: painData.limit,
  };
  viporderList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const handleSizeChange = (val) => {
  //上一页
  painData.currentPage = val;
  getList();
};
const handleCurrentChange = (val) => {
  //下一页
  painData.currentPage = val;
  getList();
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 20px;
}
.el-input {
  width: 200px !important;
  margin-right: 10px;
}
.el-form-item {
  margin-bottom: 0 !important;
}
</style>
