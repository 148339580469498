/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 16:46:57
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-24 17:17:07
 * @FilePath: \chuwu\chuwudemo\src\router\user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import photo from "@/views/operationPage/photoAlbum/photo.vue"
import title from "@/views/operationPage/title/titleTi.vue"
import remark from "@/views/operationPage/remarkP/remark.vue"
import immortal from "@/views/operationPage/immortalR/immortal.vue"
import chat from "@/views/operationPage/chattingRecords/chat.vue"
import freeze from "@/views/operationPage/freezeF/freeze.vue"
import image from "@/views/operationPage/imageAudit/image.vue"
import thawing from "@/views/operationPage/thawingW/thawing.vue"
import reply from "@/views/operationPage/replyP/reply.vue"
import dynamic from "@/views/operationPage/dynamicM/dynamic.vue"
import store from "@/views/operationPage/storeS/store.vue"
import signature from "@/views/operationPage/signatureT/signature.vue"
import userAud from "@/views/operationPage/userAudit/userAud.vue"
import check from "@/views/operationPage/checkE/check.vue"
import ipad from "@/views/operationPage/ipadL/ipad.vue"
import dubious from "@/views/operationPage/dubiousB/dubious.vue"
const operationRouts = [
  {
    path: '/photo',
    name: '运营',
    meta: { title: "运营" },
    children: [
      {
        path: '/dubious',
        name: '可疑聊天',
        component: dubious,
      },
      {
        path: '/photo',
        name: '相册举报',
        component: photo,
      },
      {
        path: '/title',
        name: '封号列表',
        component: title,
      },
      {
        path: '/remark',
        name: '评论审核',
        component: remark,
      },
      {
        path: '/immortal',
        name: '真人验证',
        component: immortal,
      },
      {
        path: '/freeze',
        name: '冻结',
        component: freeze,
      },
      {
        path: '/chat',
        name: '聊天记录',
        component: chat,
      },
      {
        path: '/image',
        name: '形象照审核',
        component: image,
      },
      {
        path: '/thawing',
        name: '解冻保证金',
        component: thawing,
      },
      {
        path: '/reply',
        name: '回复审核',
        component: reply,
      },
      {
        path: '/dynamic',
        name: '动态举报',
        component: dynamic,
      },
      {
        path: '/store',
        name: '店铺封面审核',
        component: store,
      },
      {
        path: '/signature',
        name: '签名审核',
        component: signature,
      },
      {
        path: '/userAud',
        name: '用户举报',
        component: userAud,
      },
      {
        path: '/check',
        name: '动态审核',
        component: check,
      },
      {
        path: '/ipad',
        name: '相册审核',
        component: ipad,
      },
    ]
  },
]
export default operationRouts