<template>
  <div v-if="props.status == 1">
    <el-dialog v-model="dialogTableVisible" title="详情" width="40%">
      <el-table :data="props.detailData" stripe style="width: 100%">
        <el-table-column :prop="item.prop" :label="item.label" v-for="(item, index) in clodata" :key="index">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
  <div v-if="props.status == 2">
    <el-dialog v-model="dialogTableVisible" title="详情" width="40%">
      <el-form :model="form" :rules="rules" ref="ruleFormRef">
        <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="form.name" autocomplete="off" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="苹果产品ID" :label-width="formLabelWidth" prop="apple_product_id">
          <el-input v-model="form.apple_product_id" autocomplete="off" placeholder="请输入苹果产品ID" />
        </el-form-item>
        <el-form-item label="充值金额" :label-width="formLabelWidth" prop="money">
          <el-input v-model="form.money" autocomplete="off" placeholder="请输入充值金额" />
        </el-form-item>
        <el-form-item label="IOS可得爱豆" :label-width="formLabelWidth" prop="bean_for_ios">
          <el-input v-model="form.bean_for_ios" autocomplete="off" placeholder="请输入IOS可得爱豆" />
        </el-form-item>
        <el-form-item label="安卓可得爱豆" :label-width="formLabelWidth" prop="bean_for_android">
          <el-input v-model="form.bean_for_android" autocomplete="off" placeholder="请输入安卓可得爱豆" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="queDing(ruleFormRef)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <div v-if="props.status == 3">
    <el-dialog v-model="dialogTableVisible" title="修改" width="40%">
      <el-form :model="props.upData" :rules="ruless" ref="ruleFormRefs">
        <el-form-item label="名称" :label-width="formLabelWidth" prop="name">
          <el-input v-model="props.upData.name" autocomplete="off" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="苹果产品ID" :label-width="formLabelWidth" prop="apple_product_id">
          <el-input v-model="props.upData.apple_product_id" autocomplete="off" placeholder="请输入苹果产品ID" />
        </el-form-item>
        <el-form-item label="充值金额" :label-width="formLabelWidth" prop="money">
          <el-input v-model="props.upData.money" autocomplete="off" placeholder="请输入充值金额" />
        </el-form-item>
        <el-form-item label="IOS可得爱豆" :label-width="formLabelWidth" prop="bean_for_ios">
          <el-input v-model="props.upData.bean_for_ios" autocomplete="off" placeholder="请输入IOS可得爱豆" />
        </el-form-item>
        <el-form-item label="安卓可得爱豆" :label-width="formLabelWidth" prop="bean_for_android">
          <el-input v-model="props.upData.bean_for_android" autocomplete="off" placeholder="请输入安卓可得爱豆" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="upDing(ruleFormRefs)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
const dialogTableVisible = defineModel('dialogTableVisible')
const ruleFormRef = ref()
const ruleFormRefs = ref()
const formLabelWidth = ref(140)
const props = defineProps({
  status: {
    type: Number,
    default: 0
  },
  detailData: {
    type: Array,
    default: []
  },
  upData: {
    type: Object,
    default: {}
  }
})
const form = reactive({
  name: "",
  apple_product_id: "",
  money: "",
  bean_for_ios: "",
  bean_for_android: "",
})
const clodata = reactive([
  { prop: "name", label: "商品名" },
  { prop: "apple_product_id", label: "苹果产品ID" },
  { prop: "money", label: "金额" },
  { prop: "bean_for_ios", label: "苹果可得爱豆" },
  { prop: "bean_for_android", label: "安卓可得爱豆" },
  { prop: "created_at", label: "创建时间" },
  { prop: "updated_at", label: "修改时间" },
])
const rules = reactive({
  name: [
    { required: true, message: '请输入名称', trigger: 'change' },
  ],
  apple_product_id: [
    { required: true, message: '请输入苹果产品ID', trigger: 'change' },
  ],
  money: [
    { required: true, message: '请输入充值金额', trigger: 'change' },
  ],
  bean_for_ios: [
    { required: true, message: '请输入IOS可得爱豆', trigger: 'change' },
  ],
  bean_for_android: [
    { required: true, message: '请输入安卓可得爱豆', trigger: 'change' },
  ],
})
const ruless = reactive({
  name: [
    { required: true, message: '请输入名称', trigger: 'change' },
  ],
  apple_product_id: [
    { required: true, message: '请输入苹果产品ID', trigger: 'change' },
  ],
  money: [
    { required: true, message: '请输入充值金额', trigger: 'change' },
  ],
  bean_for_ios: [
    { required: true, message: '请输入IOS可得爱豆', trigger: 'change' },
  ],
  bean_for_android: [
    { required: true, message: '请输入安卓可得爱豆', trigger: 'change' },
  ],
})
const emit = defineEmits(['sheHe', 'queDing', 'upDing'])
const quXiao = () => {
  emit('quXiao')
}
const upDing = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!',)
      emit('upDing', props.upData)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const queDing = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!', form)
      emit('queDing', form)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const sheHe = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      emit('sheHe', form)
    } else {
      console.log('error submit!', fields)
    }
  })
}

</script>