/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-25 17:53:02
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-25 17:53:11
 * @FilePath: \chuwudemo\src\http\throttle.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// #创建throttle.js
const throttle = (fn, delay) => {
  let last = 0
  return function () {
    const now = Date.now()
    if (now - last >= delay) {
      fn.apply(this, arguments)
      last = now
    }
  }
}

export default {
  mounted (el, binding) {
    const { value, arg } = binding
    const delay = arg ? parseInt(arg, 10) : 1000

    const throttledClick = throttle(value, delay)

    el.__throttledClick__ = throttledClick

    el.addEventListener('blur', throttledClick)
  },
  beforeUnmount (el) {
    console.log('Before unmount')
    if (el) {
      console.log(el)
      console.log(el.__throttledClick__)
      el.removeEventListener('click', el.__throttledClick__)
      delete el.__throttledClick__
    }
  }
}

