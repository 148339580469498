/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 16:46:57
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-19 15:57:49
 * @FilePath: \chuwu\chuwudemo\src\router\user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import gift from "@/views/allocation/giftG/gift"
import impress from "@/views/allocation/impressP/impress"
import carousel from "@/views/allocation/carouselO/carousel"
import recharge from "@/views/allocation/rechargeC/recharge"
import deploy from "@/views/allocation/deployP/deploy"
import version from "@/views/allocation/versionS/version"
import agreement from "@/views/allocation/agreementE/agreement"
import reviews from "@/views/allocation/reviewsE/reviews"
const allRouts = [
  {
    path: '/gift',
    name: '配置',
    meta: { title: "配置" },
    children: [
      {
        path: '/deploy',
        name: '配置',
        component: deploy,
      },
      {
        path: '/version',
        name: '版本',
        component: version,
      },
      {
        path: '/gift',
        name: '礼物',
        component: gift,
      },
      {
        path: '/impress',
        name: '印象标签',
        component: impress,
      },
      {
        path: '/carousel',
        name: '轮播',
        component: carousel,
      },
      {
        path: '/recharge',
        name: '充值设置',
        component: recharge,
      },
      {
        path: '/agreement',
        name: '协议设置',
        component: agreement,
      },
      {
        path: '/reviews',
        name: '上架',
        component: reviews,
      },
    ]
  },
]
export default allRouts

