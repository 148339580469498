<template>
  <div>
    <div class="search">
      <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入ID"
        v-model="searchValue.user_id"
      ></el-input>
      <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入卖家ID"
        v-model="searchValue.seller_id"
      ></el-input>
      <el-button type="primary" style="margin-left: 10px" @click="searchClick"
        >搜索</el-button
      >
      <el-button type="primary" @click="restClick">重置查询条件</el-button>
    </div>
    <div class="content">
      <div style="float: right">
        <!-- <el-button type="danger" @click="remove" :disabled="disabled">批量移除</el-button> -->
      </div>
      <tableDate
        :tableData="tableData"
        @editClick="editClick"
        @editsClick="editsClick"
      >
      </tableDate>
    </div>
    <div>
      <pagination
        :painData="painData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
      </pagination>
    </div>
    <div>
      <diaLog
        :status="status"
        v-model:dialogTableVisible="dialogTableVisible"
        :detailData="detailData"
        @sheHe="sheHe"
        @quXiao="quXiao"
        @queDing="queDing"
        :upData="upData"
        @upDing="upDing"
      >
      </diaLog>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import { refundList, tkDetails, jjDetails } from "@/http/merchadn.js";
import { ElMessage, ElMessageBox } from "element-plus";
import tableDate from "./components/tableData.vue";
import search from "@/components/search.vue";
import pagination from "@/components/pagination.vue";
import diaLog from "./components/dialog";
const tableData = ref();
const detailData = ref();
const dialogTableVisible = ref(false);
const id = ref();
const disabled = ref(true);
const list = ref();
const status = ref(0);
const painData = reactive({
  total: 0, //一共多少条
  limit: 10, //一页多少条
  currentPage: 1, //第几页
});
const searchValue = reactive({
  user_id: "", //用户ID
  seller_id: "",
});
onMounted(() => {
  getList();
});
const searchClick = () => {
  const params = {
    seller_id: searchValue.seller_id,
    user_id: searchValue.user_id,
    page: painData.currentPage,
    limit: painData.limit,
  };
  refundList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const restClick = () => {
  getList();
  Object.keys(searchValue).forEach((key) => {
    searchValue[key] = "";
  });
};
const getList = () => {
  const params = {
    page: painData.currentPage,
    limit: painData.limit,
  };
  refundList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const editClick = (e) => {
  //给买家退款
  ElMessageBox.confirm("此操作将退款给买家, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      tkDetails(e)
        .then((res) => {
          if (res.code == -1) {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          } else {
            ElMessage({
              type: "success",
              message: "退款成功",
            });
            getList();
          }
        })
        .catch(() => {});
    })
    .catch(() => {});
};
const editsClick = (e) => {
  //拒绝退款
  ElMessageBox.confirm("此操作将拒绝退款给买家, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      jjDetails(e)
        .then((res) => {
          if (res.code == -1) {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          } else {
            ElMessage({
              type: "success",
              message: "拒绝成功",
            });
            getList();
          }
        })
        .catch(() => {});
    })
    .catch(() => {});
};
const handleSizeChange = (val) => {
  //上一页
  painData.currentPage = val;
  getList();
};
const handleCurrentChange = (val) => {
  //下一页
  painData.currentPage = val;
  getList();
};
const quXiao = () => {
  dialogTableVisible.value = false;
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 20px;
}
.el-input {
  width: 200px !important;
  margin-right: 10px;
}
.el-form-item {
  margin-bottom: 0 !important;
}
</style>
