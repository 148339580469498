/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-04-17 16:35:07
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-25 16:54:12
 * @FilePath: \demo_ele\src\store\user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { defineStore } from "pinia"
export const useUserInfoStore = defineStore('userInfo', () => {
  // defineStore('userInfo',{})  userInfo就是这个仓库的名称name
  const state = (() => {
    return {
      navList: []
    }
  })

},
  {
    persist: true //数据持久保存到localStorage
  }
);
