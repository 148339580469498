<template>
  <div class="content">
    <div class="warp">
      <div v-for="(item, index) in allList" :key="index" class="top">
        <div class="childe">
          <h1>{{ item.label }}</h1>
          <div class="num">
            <span>昨日数量: 1</span>
            <span>昨日数量: 1</span>
            <span>昨日数量: 1</span>
          </div>
        </div>
      </div>
    </div>
    <div ref="info" style="width: 100%; height: 80%; margin-top: 20px"></div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, inject } from "vue";
let allList = reactive([
  { label: "访问量" },
  { label: "安装数量" },
  { label: "总数量" },
]);
const tableData = [
  {
    date: "2016-05-03",
    name: "Tom",
    address: "No. 189, Grove St, Los Angeles",
  },
  {
    date: "2016-05-02",
    name: "Tom",
    address: "No. 189, Grove St, Los Angeles",
  },
  {
    date: "2016-05-04",
    name: "Tom",
    address: "No. 189, Grove St, Los Angeles",
  },
  {
    date: "2016-05-01",
    name: "Tom",
    address: "No. 189, Grove St, Los Angeles",
  },
];
//通过inject使用echarts
const echarts = inject("echarts");

//通过ref获取html元素
const info = ref();
onMounted(() => {
  var infoEl = info.value;

  //  light dark
  var userEc = echarts.init(infoEl, "light");

  // 指定图表的配置项和数据
  const option = {
    title: {
      text: "模拟数据",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      data: ["Email", "Union Ads", "Video Ads", "Direct", "Search Engine"],
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: "Email",
        type: "line",
        stack: "Total",
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        data: [120, 132, 101, 134, 90, 230, 210],
      },
      {
        name: "Union Ads",
        type: "line",
        stack: "Total",
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        data: [220, 182, 191, 234, 290, 330, 310],
      },
      {
        name: "Video Ads",
        type: "line",
        stack: "Total",
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        data: [150, 232, 201, 154, 190, 330, 410],
      },
      {
        name: "Direct",
        type: "line",
        stack: "Total",
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        data: [320, 332, 301, 334, 390, 330, 320],
      },
      {
        name: "Search Engine",
        type: "line",
        stack: "Total",
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        data: [820, 932, 901, 934, 1290, 1330, 1320],
      },
    ],
  };

  userEc.setOption(option);
});
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  height: 1000px;
}
.warp {
  display: flex;
  width: 100%;
  .top {
    width: 33%;
    height: 150px;
    border-radius: 10px;
    margin-right: 10px;
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  }
}
.num {
  margin-top: 50px;
  span {
    display: inline-block;
    margin-right: 10px;
  }
}
</style>
