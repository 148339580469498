<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%">
      <el-table-column prop="props.tableData" label="UserID">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="UserID">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="图片">
        <template #default="{ row }">
          <span v-if="row != null">
            <img :src="row.url" alt="" style="width:60px;height:60px;">
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="审核状态">
        <template #default="{ row }">
          <span v-if="row != null">
            {{ fn[row.reviewing_status] }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="审核意见">
        <template #default="{ row }">
          <span v-if="row != null">
            {{ row.reviewing_reason }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="updated_at" label="修改时间"></el-table-column>
      <el-table-column prop="props.tableData" label="操作">
        <template #default="{ row }">
          <el-button type="primary" @click="editClick(row.id)">通过</el-button>
          <el-button type="danger" @click="editsClick(row.id)">拒绝</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue"
const props = defineProps({
  tableData: {
    type: Array,
    default: []
  },
})
const fn = reactive({
  reviewing: "待审核",
  reject: "已拒绝",
  pass: "已通过",
})
const emit = defineEmits([])
const editClick = (id) => {
  emit('editClick', id)
}
const editsClick = (id) => {
  emit('editsClick', id)
}
</script>
<style lang="scss" scoped>
.el-button+.el-button {
  margin-left: 10px !important;
}
</style>