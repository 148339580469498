<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-12 09:45:06
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-13 10:54:49
 * @FilePath: \chuwudemo\src\views\userPage\components\dialog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-dialog v-model="dialogTableVisible" title="详情" width="60%">
      <el-table :data="props.detailData">
        <el-table-column prop="headimgurl" label="头像">
          <template #default="{ row }">
            <img :src="row.headimgurl" alt="" style="width: 50px;height:50px;">
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script setup>

import { ref, onMounted, reactive } from "vue"
const dialogTableVisible = defineModel('dialogTableVisible')
const props = defineProps({
  detailData: {
    type: Array,
    default: []
  },
})

</script>
<style lang="scss"></style>