<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-12 09:29:47
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-21 14:32:36
 * @FilePath: \chuwudemo\src\views\userPage\user\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 17:00:25
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-12 10:33:57
 * @FilePath: \chuwudemo\src\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%">
      <el-table-column prop="props.tableData" label="ID">
        <template #default="{ row }">
          <span>
            {{ row.id }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户ID">
        <template #default="{ row }">
          <span v-if="row.user != null">
            {{ row.user.id }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户名">
        <template #default="{ row }">
          <span v-if="row.user != null">
            {{ row.user.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="订单号">
        <template #default="{ row }">
          <span>
            {{ row.order_no }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="爱豆数量">
        <template #default="{ row }">
          <span>
            {{ row.amount }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="实际获得金额">
        <template #default="{ row }">
          <span>
            {{ row.real_money }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="爱豆兑换余额百分比">
        <template #default="{ row }">
          <span>
            {{ row.bean_exchange_ratio }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue"
const props = defineProps({
  tableData: {
    type: Array,
    default: []
  },
})
const fn = reactive(
  { alipay: "支付宝", wxpay: "微信", apple: "苹果", alipay_wap: "支付宝网页" }
)

const fns = reactive(
  { paid: "已支付", pending: "未支付" }
)

const emit = defineEmits(['detailsClick'])
const detailsClick = (id) => {
  emit('detailsClick', id)
}
</script>
<style lang="scss"></style>
