/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 11:00:05
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-20 15:56:08
 * @FilePath: \chuwu\chuwudemo\src\http\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import service from '@/utils/request'
// get请求用params,post请求用data
//导出api方法
export function refundList (params) {
  return service({
    url: 'good/refund',
    method: 'get',
    params: params
  })
}
export function tkDetails (id) {
  return service({
    url: `good/refund/refund/${id}`,
    method: 'get',
  })
}
export function jjDetails (id) {
  return service({
    url: `good/refund/closed/${id}`,
    method: 'get',
  })
}
export function shopList (params) {
  return service({
    url: 'good/goods',
    method: 'get',
    params: params
  })
}
export function upShop (params, id) {
  return service({
    url: `good/goods/examine/${id}`,
    method: 'put',
    params: params
  })
}
export function deleteShop (id) {
  return service({
    url: `good/goods/${id}`,
    method: 'delete',
  })
}
export function orderList (params) {
  return service({
    url: 'good/order',
    method: 'get',
    params: params
  })
}
export function shopImgList (params) {
  return service({
    url: 'good/image',
    method: 'get',
    params: params
  })
}
export function deleteShopImg (id) {
  return service({
    url: `good/image/${id}`,
    method: 'delete',
  })
}