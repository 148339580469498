<template>
  <div class="header">
    <!-- 菜单图标 -->
    <div class="menu"></div>
    <!-- content -->
    <div class="header-content">
      <div class="block">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <div class="onLogin">
        <el-dropdown>
          <el-icon class="el-icon--right" size="20" color="black"
            ><arrow-down
          /></el-icon>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="onLogin">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="breadcrumb">
      <!-- 面包屑 -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item to="/userIndex">
          <el-button class="but"> 首页 </el-button>
        </el-breadcrumb-item>
        <template v-for="(item, index) in breadList">
          <el-breadcrumb-item v-if="item.name" :key="index" :to="item.path">
            <el-button class="but">
              {{ item.name }}
            </el-button>
          </el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { unLogin } from "@/http/index.js";
import { ElMessage, ElMessageBox } from "element-plus";
const route = useRoute();
const router = useRouter();
// const breadcrumbItems = ref({});
const breadList = ref();
const squareUrl = ref(
  "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"
);
const onLogin = () => {
  ElMessageBox.confirm("此操作将退出登录, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      unLogin()
        .then((res) => {
          ElMessage({
            type: "success",
            message: "退出登录成功",
          });
          router.push({
            path: "/",
          });
          sessionStorage.clear();
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "退出登录失败",
          });
        });
    })
    .catch(() => {});
};
const matchList = () => {
  breadList.value = route.matched;
};
onMounted(() => {
  // updateBreadcrumbs();
  matchList();
});

watch(route, () => {
  // updateBreadcrumbs();
  // matchList()
  breadList.value = route.matched;
});

const updateBreadcrumbs = () => {
  let currentRoute = route;
  const items = ref([]);
  const label = currentRoute.meta.title || currentRoute.name;
  if (label == "首页") {
    breadcrumbItems.value = {};
  } else {
    breadcrumbItems.value.name = currentRoute.name;
    breadcrumbItems.value.path = currentRoute.path;
  }
};
</script>
<style lang="scss">
.but {
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  font-size: 16px;
  font-weight: 600;
}
.breadcrumb {
  width: 80%;
  height: 40px;
  // backgr.ound-color: red;
  position: absolute;
  left: 40px;
  bottom: 0;
  display: flex;
  align-items: center;
}

.el-header {
  height: 70px !important;
  padding: 0 !important;
}

.header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2a5298;
  position: relative;
}

.header-content {
  position: relative;
}

.onLogin {
  position: absolute;
  top: 40px;
  right: 25px;
}

.block {
  width: 60px;
  height: 60px;
  margin: 10px 50px 0 0;
  text-align: center;
  background: skyblue;
  border-radius: 50%;
  font-size: 14px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
</style>
