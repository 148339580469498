<template>
  <div>
    <div>
      <!-- <search></search> -->
    </div>
    <div class="content">
      <div style="float: right">
        <!-- <el-button type="danger" @click="remove" :disabled="disabled">批量移除</el-button> -->
      </div>
      <div style="float: right; margin-right: 10px">
        <el-button type="primary" @click="create">创建</el-button>
      </div>
      <tableDate
        :tableData="tableData"
        @handleSelectionChange="handleSelectionChange"
        @deleteClick="deleteClick"
        @detailsClick="detailsClick"
        @editClick="editClick"
        @changSwitch="changSwitch"
      >
      </tableDate>
    </div>
    <div>
      <pagination
        :painData="painData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
      </pagination>
    </div>
    <div>
      <diaLog
        :status="status"
        v-model:dialogTableVisible="dialogTableVisible"
        :detailData="detailData"
        @sheHe="sheHe"
        @quXiao="quXiao"
        @queDing="queDing"
        :upData="upData"
        @upDing="upDing"
      >
      </diaLog>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  rechargeList,
  rechargeDetails,
  deleteRecharge,
  createRecharge,
  updataRecharge,
} from "@/http/allocation.js";
import { ElMessage, ElMessageBox } from "element-plus";
import tableDate from "./components/tableData.vue";
import search from "@/components/search.vue";
import pagination from "@/components/pagination.vue";
import diaLog from "./components/dialog";
const tableData = ref();
const detailData = ref();
const dialogTableVisible = ref(false);
const id = ref();
const disabled = ref(true);
const list = ref();
const status = ref(0);
const painData = reactive({
  total: 0, //一共多少条
  limit: 10, //一页多少条
  currentPage: 1, //第几页
});
onMounted(() => {
  getList();
});
const upData = reactive({
  name: "",
  apple_product_id: "",
  money: "",
  bean_for_ios: "",
  bean_for_android: "",
});
const getList = () => {
  const params = {
    page: painData.currentPage,
    limit: painData.limit,
  };
  rechargeList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const detailsClick = (e) => {
  id.value = e;
  status.value = 1;
  dialogTableVisible.value = true;
  getDetail();
};
const getDetail = () => {
  rechargeDetails(id.value)
    .then((res) => {
      detailData.value = [res.data];
      upData.name = res.data.name;
      upData.apple_product_id = res.data.apple_product_id;
      upData.money = res.data.money;
      upData.bean_for_ios = res.data.bean_for_ios;
      upData.bean_for_android = res.data.bean_for_android;
    })
    .catch((error) => {});
};
const create = () => {
  status.value = 2;
  dialogTableVisible.value = true;
};
const queDing = (form) => {
  createRecharge(form)
    .then((res) => {
      console.log(res);
      if (res.code == 200) {
        ElMessage({
          type: "success",
          message: "创建成功",
        });
        getList();
        dialogTableVisible.value = false;
        form.name = "";
        form.apple_product_id = "";
        form.money = "";
        form.bean_for_ios = "";
        form.bean_for_android = "";
      } else {
        ElMessage({
          type: "error",
          message: res.msg,
        });
        dialogTableVisible.value = false;
        form.name = "";
        form.apple_product_id = "";
        form.money = "";
        form.bean_for_ios = "";
        form.bean_for_android = "";
      }
    })
    .catch((error) => {});
};
const deleteClick = (e) => {
  //单个删除
  ElMessageBox.confirm("此操作将删除部分数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      deleteRecharge(e)
        .then((res) => {
          if (res.code == -1) {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          } else {
            ElMessage({
              type: "success",
              message: "删除成功",
            });
            getList();
          }
        })
        .catch(() => {});
    })
    .catch(() => {});
};
// const remove = () => { //批量删除
//   ElMessageBox.confirm(
//     '此操作将删除部分数据, 是否继续?', '提示',
//     {
//       confirmButtonText: '确定',
//       cancelButtonText: '取消',
//       type: 'warning',
//     }
//   ).then(() => {
//     let params = { id: list.value.toString() }
//     console.log(params)
//     deleteImpress(params).then((res) => {
//       if (res.code == -1) {
//         ElMessage({
//           type: 'error',
//           message: res.msg,
//         })
//       } else {
//         ElMessage({
//           type: 'success',
//           message: "删除成功",
//         })
//         getList()
//       }
//     }).catch(() => {

//     })
//   }).catch(() => {

//   })
// }
// const handleSelectionChange = (e) => {
//   list.value = e.map((item) => item.id);
//   if (e == '') {
//     disabled.value = true
//   } else {
//     disabled.value = false
//   }
// }
const editClick = (e) => {
  dialogTableVisible.value = true;
  status.value = 3;
  id.value = e;
  getDetail();
};
const upDing = (form) => {
  updataRecharge(form, id.value)
    .then((res) => {
      if (res.code == 200) {
        ElMessage({
          type: "success",
          message: "修改成功",
        });
        getList();
        dialogTableVisible.value = false;
      } else {
        ElMessage({
          type: "error",
          message: res.msg,
        });
        getList();
        dialogTableVisible.value = false;
      }
    })
    .catch((error) => {});
};
const handleSizeChange = (val) => {
  //上一页
  painData.currentPage = val;
  getList();
};
const handleCurrentChange = (val) => {
  //下一页
  painData.currentPage = val;
  getList();
};
const quXiao = () => {
  dialogTableVisible.value = false;
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 20px;
}
</style>
