<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-12 09:29:47
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-26 13:56:32
 * @FilePath: \chuwudemo\src\views\userPage\user\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 17:00:25
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-12 10:33:57
 * @FilePath: \chuwudemo\src\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%">
      <el-table-column prop="props.tableData" label="ID">
        <template #default="{ row }">
          <span v-if="row != null">
            {{ row.id }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户ID">
        <template #default="{ row }">
          <span v-if="row.user != null">
            {{ row.user.id }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户昵称">
        <template #default="{ row }">
          <span v-if="row.user != null">
            {{ row.user.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="单号">
        <template #default="{ row }">
          <span>
            {{ row.order_no }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="状态">
        <template #default="{ row }">
          <span>
            {{ fn[row.type] }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="去向">
        <template #default="{ row }">
          <span>
            {{ fns[row.direction] }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="变动前">
        <template #default="{ row }">
          <span>
            {{ row.after }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="变动后">
        <template #default="{ row }">
          <span>
            {{ row.before }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue";
const props = defineProps({
  tableData: {
    type: Array,
    default: [],
  },
});
const fn = reactive({ refund_goods: "赠送礼物", buy_goods: "获得礼物" });

const fns = reactive({ in: "进", out: "出" });

const emit = defineEmits(["detailsClick"]);
const detailsClick = (id) => {
  emit("detailsClick", id);
};
</script>
<style lang="scss"></style>
