<template>
  <div v-if="props.status == 1">
    <el-dialog v-model="dialogTableVisible" title="详情" width="40%">
      <el-table :data="props.detailData" stripe style="width: 100%">
        <el-table-column :prop="item.props" :label="item.label" v-for="(item, index) in clodata" :key="index">
        </el-table-column>
        <el-table-column prop="props.detailData" label="图片">
          <template #default="{ row }">
            <img :src="row.path" alt="" style="width:50px;height:50px;">
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="状态">
          <template #default="{ row }">
            <el-switch v-model="row.status" class="ml-2"
              style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" disabled :active-value="1"
              :inactive-value="2" />
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间"></el-table-column>
        <el-table-column prop="updated_at" label="更新时间"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
  <div v-if="props.status == 2">
    <el-dialog v-model="dialogTableVisible" title="详情" width="40%">
      <el-form :model="form" :rules="rules" ref="ruleFormRef">
        <el-form-item label="礼物名称" :label-width="formLabelWidth" prop="gift_name">
          <el-input v-model="form.gift_name" autocomplete="off" placeholder="请输入礼物名称" />
        </el-form-item>
        <el-form-item label="礼物图片" :label-width="formLabelWidth" prop="path">
          <el-input v-model="form.path" autocomplete="off" placeholder="请输入礼物图片" />
        </el-form-item>
        <el-form-item label="爱豆" :label-width="formLabelWidth" prop="path">
          <el-input v-model="form.bean" autocomplete="off" placeholder="请输入爱豆数量" />
        </el-form-item>
        <el-form-item label="是否上架" :label-width="formLabelWidth" prop="status">
          <el-switch v-model="form.status" class="ml-2"
            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" active-value="1" inactive-value="2" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="queDing(ruleFormRef)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <div v-if="props.status == 3">
    <el-dialog v-model="dialogTableVisible" title="修改" width="40%">
      <el-form :model="props.upData" :rules="ruless" ref="ruleFormRefs">
        <el-form-item label="礼物名称" :label-width="formLabelWidth" prop="gift_name">
          <el-input v-model="upData.gift_name" autocomplete="off" placeholder="请输入礼物名称" />
        </el-form-item>
        <el-form-item label="礼物图片" :label-width="formLabelWidth" prop="path">
          <el-input v-model="upData.path" autocomplete="off" placeholder="请输入礼物图片" />
        </el-form-item>
        <el-form-item label="爱豆" :label-width="formLabelWidth" prop="path">
          <el-input v-model="upData.bean" autocomplete="off" placeholder="请输入爱豆数量" />
        </el-form-item>
        <el-form-item label="是否上架" :label-width="formLabelWidth" prop="status">
          <el-switch v-model="upData.status" class="ml-2"
            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" :active-value="1"
            :inactive-value="2" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="upDing(ruleFormRefs)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
const dialogTableVisible = defineModel('dialogTableVisible')
const ruleFormRef = ref()
const ruleFormRefs = ref()
const formLabelWidth = ref(140)
const props = defineProps({
  status: {
    type: Number,
    default: 0
  },
  detailData: {
    type: Array,
    default: []
  },
  upData: {
    type: Object,
    default: {}
  }
})
const clodata = reactive([
  { props: "gift_name", label: "礼物名称" },
  { props: "bean", label: "爱豆" },
])
const form = reactive({
  gift_name: "",
  path: "",
  bean: "",
  status: false,
})
const rules = reactive({
  gift_name: [
    { required: true, message: '请输入礼物名字', trigger: 'change' },
  ],
  path: [
    { required: true, message: '请输入礼物图片', trigger: 'change' },
  ],
  bean: [
    { required: true, message: '请输入爱豆数量', trigger: 'change' },
  ],
  status: [
    { required: true, message: '请选择状态', trigger: 'change' },
  ],
})
const ruless = reactive({
  gift_name: [
    { required: true, message: '请输入礼物名字', trigger: 'change' },
  ],
  path: [
    { required: true, message: '请输入礼物图片', trigger: 'change' },
  ],
  bean: [
    { required: true, message: '请输入爱豆数量', trigger: 'change' },
  ],
  status: [
    { required: true, message: '请选择状态', trigger: 'change' },
  ],
})
const emit = defineEmits(['sheHe', 'queDing', 'upDing'])
const quXiao = () => {
  emit('quXiao')
}
const upDing = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!',)
      emit('upDing', props.upData)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const queDing = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!', form)
      emit('queDing', form)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const sheHe = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      emit('sheHe', form)
    } else {
      console.log('error submit!', fields)
    }
  })
}
</script>