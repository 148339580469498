<template>
  <div>
    <div class="search">
      <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入ID"
        v-model="searchValue.user_id"
      ></el-input>
      <!-- <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入订单号"
        v-model="searchValue.give_user_id"
      ></el-input> -->
      <el-button type="primary" style="margin-left: 10px" @click="searchClick"
        >搜索</el-button
      >
      <el-button type="primary" @click="restClick">重置查询条件</el-button>
    </div>
    <!-- 比如这个页面需要详情和删除按钮 -->
    <div class="content">
      <tableDate :tableData="tableData"></tableDate>
    </div>
    <div>
      <pagination
        :painData="painData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
      </pagination>
    </div>
  </div>
</template>
<script setup>
// 使用封装的表单做到所有页面只需要调这一个组件包括内部定义的按钮事件
import { ref, reactive, onMounted } from "vue";
import { giftorderList } from "@/http/user.js";
import tableDate from "./components/tableData.vue";
import search from "@/components/search.vue";
import pagination from "@/components/pagination.vue";
const tableData = ref();
const searchValue = reactive({
  user_id: "", //用户ID
  give_user_id: "",
});
const painData = reactive({
  total: 0, //一共多少条
  limit: 10, //一页多少条
  currentPage: 1, //第几页
});
onMounted(() => {
  getList();
});
const searchClick = () => {
  const params = {
    user_id: searchValue.user_id,
    page: painData.currentPage,
    limit: painData.limit,
  };
  giftorderList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const restClick = () => {
  getList();
  Object.keys(searchValue).forEach((key) => {
    searchValue[key] = "";
  });
};
const getList = () => {
  const params = {
    page: painData.currentPage,
    limit: painData.limit,
  };
  giftorderList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const handleSizeChange = (val) => {
  //上一页
  painData.currentPage = val;
  getList();
};
const handleCurrentChange = (val) => {
  //下一页
  painData.currentPage = val;
  getList();
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 20px;
}
.el-input {
  width: 200px !important;
  margin-right: 10px;
}
.el-form-item {
  margin-bottom: 0 !important;
}
</style>
