<template>
  <div v-if="props.status == 1">
    <el-dialog v-model="dialogTableVisible" title="详情" width="40%">
      <el-table :data="props.detailData" stripe style="width: 100%">
        <el-table-column prop="props.detailData" label="内容">
          <template #default="{ row }">
            <span v-if="row != null">{{ row.content }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="标题">
          <template #default="{ row }">
            <span>{{ row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="props.detailData" label="是否展示">
          <template #default="{ row }">
            <el-switch v-model="row.is_display" class="ml-2"
              style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" :active-value="1"
              :inactive-value="0" disabled />
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间"></el-table-column>
        <el-table-column prop="updated_at" label="更新时间"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
  <div v-if="props.status == 2">
    <el-dialog v-model="dialogTableVisible" title="详情" width="40%">
      <el-form :model="form" :rules="rules" ref="ruleFormRef">
        <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
          <el-input v-model="form.title" autocomplete="off" placeholder="请输入标签名" />
        </el-form-item>
        <el-form-item label="内容" :label-width="formLabelWidth" prop="content">
          <el-input v-model="form.content" autocomplete="off" placeholder="请输入内容" />
        </el-form-item>
        <el-form-item label="是否展示" :label-width="formLabelWidth" prop="is_display">
          <el-switch v-model="form.is_display" class="ml-2"
            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" :active-value="1"
            :inactive-value="0" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="queDing(ruleFormRef)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <div v-if="props.status == 3">
    <el-dialog v-model="dialogTableVisible" title="修改" width="40%">
      <el-form :model="props.upData" :rules="ruless" ref="ruleFormRefs">
        <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
          <el-input v-model="props.upData.title" autocomplete="off" placeholder="请输入标签名" />
        </el-form-item>
        <el-form-item label="内容" :label-width="formLabelWidth" prop="content">
          <el-input v-model="props.upData.content" autocomplete="off" placeholder="请输入内容" />
        </el-form-item>
        <el-form-item label="是否展示" :label-width="formLabelWidth" prop="is_display">

          <el-switch v-model="props.upData.is_display" class="ml-2"
            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" :active-value="1"
            :inactive-value="0" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="quXiao">取消</el-button>
          <el-button type="primary" @click="upDing(ruleFormRefs)">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue"
const dialogTableVisible = defineModel('dialogTableVisible')
const ruleFormRef = ref()
const ruleFormRefs = ref()
const formLabelWidth = ref(140)
const props = defineProps({
  status: {
    type: Number,
    default: 0
  },
  detailData: {
    type: Array,
    default: []
  },
  upData: {
    type: Object,
    default: {}
  }
})
const form = reactive({
  title: "",
  content: "",
  is_display: 0
})
const rules = reactive({
  title: [
    { required: true, message: '请输入标题', trigger: 'change' },
  ],
  content: [
    { required: true, message: '请输入内容', trigger: 'change' },
  ],
  is_display: [
    { required: true, message: '请选择是否显示', trigger: 'change' },
  ],
})
const ruless = reactive({
  title: [
    { required: true, message: '请输入标题', trigger: 'change' },
  ],
  content: [
    { required: true, message: '请输入内容', trigger: 'change' },
  ],
  is_display: [
    { required: true, message: '请选择是否显示', trigger: 'change' },
  ],
})
const emit = defineEmits(['sheHe', 'queDing', 'upDing'])
const quXiao = () => {
  emit('quXiao')
}
const upDing = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!',)
      emit('upDing', props.upData)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const queDing = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!', form)
      emit('queDing', form)
    } else {
      console.log('error submit!', fields)
    }
  })
}
const sheHe = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      emit('sheHe', form)
    } else {
      console.log('error submit!', fields)
    }
  })
}

</script>