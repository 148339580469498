<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 17:00:25
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-12 15:07:25
 * @FilePath: \chuwudemo\src\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%">
      <el-table-column prop="props.tableData" label="举报人用户ID">
        <template #default="{ row }">
          <span>{{ row.user.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="举报人用户名">
        <template #default="{ row }">
          <span>{{ row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="被举报人用户ID">
        <template #default="{ row }">
          <span v-if="row.report_user != null">{{ row.report_user.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="被举报人用户名">
        <template #default="{ row }">
          <span v-if="row.report_user != null">{{ row.report_user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="图片">
        <template #default="{ row }">
          <img :src="row.album_photo.url" alt="" style="width:50px;height:50px;">
        </template>
      </el-table-column>
      <el-table-column prop="reason" label="原因"></el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="updated_at" label="更新时间"></el-table-column>
      <el-table-column prop="address" label="操作">
        <template #default="{ row }">
          <el-button type="primary" @click="detailsClick(row.id)">详情</el-button>
          <el-button type="danger" @click="deleteClick(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue"
const props = defineProps({
  tableData: {
    type: Array,
    default: []
  },
})
const fn = reactive({
  0: "未知",
  1: "男",
  2: "女",
})
const fns = reactive({
  0: "否",
  1: "是",
})
const vip = reactive({
  1: "白银",
  2: "黄金",
})
const emit = defineEmits(['detailsClick', 'deleteClick'])
const detailsClick = (id) => {
  emit('detailsClick', id)
}
const deleteClick = (id) => {
  emit('deleteClick', id)
}
</script>
<style lang="scss" scoped>
span {
  margin-top: 20px;
  font-size: 15px;
  color: black;

}
</style>
