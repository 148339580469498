<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 11:37:17
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-20 16:09:27
 * @FilePath: \chuwudemo\src\views\basicsPage\basics.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="wrappers">
    <div class="left">
      <div>
        <h2>安装量</h2>
      </div>
      <div v-if="JSON.stringify(installData) != '{}'" class="install">
        <h2>今日数量 :</h2>
        <h2>苹果: {{ installData.today.iPhone }}</h2>
        <h2>安卓: {{ installData.today.Android }}</h2>
      </div>
      <div v-if="JSON.stringify(installData) != '{}'" class="install">
        <h2>昨日数量 :</h2>
        <h2>苹果: {{ installData.yesterday.iPhone }}</h2>
        <h2>安卓: {{ installData.yesterday.Android }}</h2>
      </div>
      <div v-if="JSON.stringify(installData) != '{}'" class="install">
        <h2>总数量 :</h2>
        <h2>苹果: {{ installData.total.iPhone }}</h2>
        <h2>安卓: {{ installData.total.Android }}</h2>
      </div>
    </div>
    <div class="right">
      <div>
        <h2>访问数量</h2>
      </div>
      <div v-if="JSON.stringify(zoneData) != '{}'" class="zone">
        <h2>今日数量:</h2>
        <h2>国内: {{ zoneData.today.china }}</h2>
        <h2>海外: {{ installData.today.not_china }}</h2>
      </div>
      <div v-if="JSON.stringify(zoneData) != '{}'" class="zone">
        <h2>昨日数量:</h2>
        <h2>国内: {{ zoneData.yesterday.china }}</h2>
        <h2>海外: {{ installData.yesterday.not_china }}</h2>
      </div>
    </div>
  </div>
</template>
<script setup>
import { basicsList } from "@/http/basics.js";
import { onMounted, reactive, ref, toRef } from "vue";
const installData = ref({});
const zoneData = ref({});
onMounted(() => {
  getList();
});
const getList = () => {
  //获取数据
  basicsList()
    .then((res) => {
      installData.value = res.data.install;
      zoneData.value = res.data.zone;
    })
    .catch((err) => {});
};
</script>
<style lang="scss" scoped>
.wrappers {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -30%);
  color: white;

  .left {
    width: 500px;
    height: 300px;
    background: #545c64;
    border-radius: 10px;

    .install {
      display: flex;
      justify-content: space-around;
    }
  }

  .right {
    width: 500px;
    height: 300px;
    background: #545c64;
    border-radius: 10px;

    .zone {
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>
