<template>
  <div>
    <div>
      <!-- <search></search> -->
    </div>
    <div class="content">
      <div style="float: right">
        <el-button type="danger" @click="remove" :disabled="disabled"
          >批量移除</el-button
        >
      </div>
      <div style="float: right; margin-right: 10px">
        <el-button type="primary" @click="create">创建</el-button>
      </div>
      <tableDate
        :tableData="tableData"
        @handleSelectionChange="handleSelectionChange"
        @deleteClick="deleteClick"
        @detailsClick="detailsClick"
        @editClick="editClick"
      >
      </tableDate>
    </div>
    <div>
      <pagination
        :painData="painData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
      </pagination>
    </div>
    <div>
      <diaLog
        :status="status"
        v-model:dialogTableVisible="dialogTableVisible"
        :detailData="detailData"
        @sheHe="sheHe"
        @quXiao="quXiao"
        @queDing="queDing"
        :upData="upData"
        @upDing="upDing"
      >
      </diaLog>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  giftList,
  giftDetails,
  deleteGift,
  updataGift,
  createGift,
} from "@/http/allocation.js";
import { ElMessage, ElMessageBox } from "element-plus";
import tableDate from "./components/tableData.vue";
import search from "@/components/search.vue";
import pagination from "@/components/pagination.vue";
import diaLog from "./components/dialog";
const tableData = ref();
const detailData = ref();
const dialogTableVisible = ref(false);
const id = ref();
const disabled = ref(true);
const list = ref();
const status = ref(0);
const painData = reactive({
  total: 0, //一共多少条
  limit: 10, //一页多少条
  currentPage: 1, //第几页
});
onMounted(() => {
  getList();
});
const upData = reactive({
  gift_name: "",
  path: "",
  bean: "",
  status: false,
});
const getList = () => {
  const params = {
    page: painData.currentPage,
    limit: painData.limit,
  };
  giftList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const detailsClick = (e) => {
  id.value = e;
  status.value = 1;
  dialogTableVisible.value = true;
  getDetail();
};
const getDetail = () => {
  giftDetails(id.value)
    .then((res) => {
      detailData.value = [res.data];
      upData.gift_name = res.data.gift_name;
      upData.path = res.data.path;
      upData.bean = res.data.bean;
      upData.status = res.data.status;
    })
    .catch((error) => {});
};
const create = () => {
  status.value = 2;
  dialogTableVisible.value = true;
};
const queDing = (form) => {
  createGift(form)
    .then((res) => {
      console.log(res);
      if (res.code == 200) {
        ElMessage({
          type: "success",
          message: "创建成功",
        });
        getList();
        dialogTableVisible.value = false;
        form.gift_name = "";
        form.path = "";
        form.bean = "";
        form.status = 2;
      } else {
        ElMessage({
          type: "error",
          message: res.msg,
        });
        dialogTableVisible.value = false;
        form.gift_name = "";
        form.path = "";
        form.bean = "";
        form.status = 2;
      }
    })
    .catch((error) => {});
};
const deleteClick = (e) => {
  //单个删除
  ElMessageBox.confirm("此操作将删除部分数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let params = {
        id: e,
      };
      deleteGift(params)
        .then((res) => {
          if (res.code == -1) {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          } else {
            ElMessage({
              type: "success",
              message: "删除成功",
            });
            getList();
          }
        })
        .catch(() => {});
    })
    .catch(() => {});
};
const remove = () => {
  //批量删除
  ElMessageBox.confirm("此操作将删除部分数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let params = { id: list.value.toString() };
      console.log(params);
      deleteGift(params)
        .then((res) => {
          if (res.code == -1) {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          } else {
            ElMessage({
              type: "success",
              message: "删除成功",
            });
            getList();
          }
        })
        .catch(() => {});
    })
    .catch(() => {});
};
const handleSelectionChange = (e) => {
  list.value = e.map((item) => item.id);
  if (e == "") {
    disabled.value = true;
  } else {
    disabled.value = false;
  }
};
const editClick = (e) => {
  dialogTableVisible.value = true;
  status.value = 3;
  id.value = e;
  getDetail();
};
const upDing = (form) => {
  updataGift(form, id.value)
    .then((res) => {
      if (res.code == 200) {
        ElMessage({
          type: "success",
          message: "修改成功",
        });
        getList();
        dialogTableVisible.value = false;
      } else {
        ElMessage({
          type: "error",
          message: res.msg,
        });
        getList();
        dialogTableVisible.value = false;
      }
    })
    .catch((error) => {});
};
const handleSizeChange = (val) => {
  //上一页
  painData.currentPage = val;
  getList();
};
const handleCurrentChange = (val) => {
  //下一页
  painData.currentPage = val;
  getList();
};
const quXiao = () => {
  dialogTableVisible.value = false;
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 20px;
}
</style>
