<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-13 14:28:10
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-20 10:47:56
 * @FilePath: \chuwudemo\src\views\operationPage\replyP\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%" @selection-change="handleSelectionChange">
      <!-- <el-table-column type="selection" width="40"></el-table-column> -->
      <el-table-column prop="props.tableData" label="UserID">
        <template #default="{ row }">
          <span>{{ row.user_id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户名">
        <template #default="{ row }">
          <span>{{ row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="卖家ID">
        <template #default="{ row }">
          <span>{{ row.seller.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="卖家昵称">
        <template #default="{ row }">
          <span>{{ row.seller.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="产品介绍">
        <template #default="{ row }">
          <span>{{ row.content }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="审核状态">
        <template #default="{ row }">
          <span>{{ fn[row.reviewing_status] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="updated_at" label="修改时间"></el-table-column>
      <el-table-column prop="props.tableData" label="操作" width="240px">
        <template #default="{ row }" style="display:flex">
          <el-button type="danger" @click="editClick(row.id)"
            v-if="row.reviewing_status == 'reviewing'">给买家退款</el-button>
          <el-button type="danger" @click="editsClick(row.id)" v-if="row.reviewing_status == 'reviewing'">拒绝</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue"
const props = defineProps({
  tableData: {
    type: Array,
    default: []
  },
})
const fn = reactive({
  reviewing: "待卖家审核",
  reject: "卖家已拒绝",
  refunded: "卖家已通过",
  closed: "平台已拒绝",
  pass: "平台已通过"
})
const emit = defineEmits(['editClick', 'editsClick', 'deleteClick', 'handleSelectionChange'])
const editClick = (id) => {
  emit('editClick', id)
}
const handleSelectionChange = (e) => {
  emit('handleSelectionChange', e)
}
const editsClick = (id) => {
  emit('editsClick', id)
}
const deleteClick = (id) => {
  emit('deleteClick', id)
}
</script>