<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-13 14:28:10
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-26 17:09:47
 * @FilePath: \chuwudemo\src\views\operationPage\replyP\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table
      :data="props.tableData"
      stripe
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column type="selection" width="40"></el-table-column> -->
      <el-table-column prop="props.tableData" label="ID">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="UserID">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户名">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="卖家ID">
        <template #default="{ row }">
          <span v-if="row.seller != null">{{ row.seller.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="卖家">
        <template #default="{ row }">
          <span v-if="row.seller != null">{{ row.seller.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="订单号">
        <template #default="{ row }">
          <span>{{ row.order_no }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="买入数量">
        <template #default="{ row }">
          <span>{{ row.count }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="总金额">
        <template #default="{ row }">
          <span>{{ row.total_money }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="Status">
        <template #default="{ row }">
          <span>{{ st[row.status] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="支付方式">
        <template #default="{ row }">
          <span>{{ fn[row.payment_type] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="备注">
        <template #default="{ row }">
          <span>{{ row.remark }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="收货城市">
        <template #default="{ row }">
          <span>{{ row.address }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="备注">
        <template #default="{ row }">
          <span>{{ row.remark }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="updated_at" label="修改时间"></el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue";
const props = defineProps({
  tableData: {
    type: Array,
    default: [],
  },
});
const st = reactive({
  pending: "待处理",
  paid: "已支付",
  shipping: "已发货",
  received: "已收货",
  completed: "已完成",
  refunding: "已申请退款",
  refunded: "已退款",
  rejected: "商家拒绝退款",
  closed: "平台拒绝退款",
  timeout: "已超时",
  canceled: "已取消",
});
const fn = reactive({
  alipay: "支付宝",
  balance: "余额",
  wechat: "微信",
});
</script>
