<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-21 16:06:57
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-26 16:35:43
 * @FilePath: \chuwudemo\src\views\userPage\permissionI\permission.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE

-->
<template>
  <div>
    <div class="search">
      <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入ID"
        v-model="searchValue.user_id"
      ></el-input>
      <el-button type="primary" style="margin-left: 10px" @click="searchClick"
        >搜索</el-button
      >
      <el-button type="primary" @click="restClick">重置查询条件</el-button>
    </div>
    <!-- 比如这个页面需要详情和删除按钮 -->
    <div class="content">
      <tableDate
        :tableData="tableData"
        @stClick="stClick"
        @detailsClick="detailsClick"
        @editClick="editClick"
      ></tableDate>
    </div>
    <div>
      <pagination
        :painData="painData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
      </pagination>
    </div>
    <div>
      <diaLog
        v-model:dialogTableVisible="dialogTableVisible"
        :status="status"
        :detailData="detailData"
        :upData="upData"
        @qxShe="qxShe"
        @quan="quan"
      ></diaLog>
    </div>
  </div>
</template>
<script setup>
// 使用封装的表单做到所有页面只需要调这一个组件包括内部定义的按钮事件
import { ref, reactive, onMounted } from "vue";
import {
  permissionList,
  detailsPermission,
  upPermission,
  editPermission,
} from "@/http/user.js";
import { ElMessage, ElMessageBox } from "element-plus";
import tableDate from "./components/tableData.vue";
import search from "@/components/search.vue";
import pagination from "@/components/pagination.vue";
import diaLog from "./components/dialog.vue";
const tableData = ref();
const dialogTableVisible = ref(false);
const id = ref();
const status = ref(0);
const detailData = ref();
const searchValue = reactive({
  user_id: "", //用户ID
});
const painData = reactive({
  total: 0, //一共多少条
  limit: 10, //一页多少条
  currentPage: 1, //第几页
});
const upData = reactive({
  status: "",
  start_at: "",
  end_at: "",
});
onMounted(() => {
  getList();
});
const qxShe = (form) => {
  ElMessageBox.confirm("此操作将修改该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      editPermission(form, id.value).then((res) => {
        if (res.code == -1) {
          ElMessage({
            type: "error",
            message: res.msg,
          });
        } else {
          ElMessage({
            type: "success",
            message: "修改成功",
          });
          getList();
          dialogTableVisible.value = false;
        }
      });
    })
    .catch((error) => {});
};
const quan = () => {
  dialogTableVisible.value = false;
};
const editClick = (e) => {
  status.value = 1;
  id.value = e;
  dialogTableVisible.value = true;
  getDetails();
};
const detailsClick = (e) => {
  id.value = e;
  status.value = 0;
  dialogTableVisible.value = true;
  getDetails();
};
const getDetails = () => {
  detailsPermission(id.value)
    .then((res) => {
      detailData.value = [res.data];
      upData.status = res.data.status;
      upData.start_at = res.data.start_at;
      upData.end_at = res.data.end_at;
    })
    .catch((err) => {});
};
const searchClick = () => {
  const params = {
    user_id: searchValue.user_id,
    page: painData.currentPage,
    limit: painData.limit,
  };
  permissionList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const restClick = () => {
  getList();
  Object.keys(searchValue).forEach((key) => {
    searchValue[key] = "";
  });
};
const getList = () => {
  const params = {
    page: painData.currentPage,
    limit: painData.limit,
  };
  permissionList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const handleSizeChange = (val) => {
  //上一页
  painData.currentPage = val;
  getList();
};
const handleCurrentChange = (val) => {
  //下一页
  painData.currentPage = val;
  getList();
};
const chang = () => {
  ElMessageBox.confirm("此操作将禁用该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let params = {
        status: 0,
        id: id.value,
      };
      upPermission(params).then((res) => {
        if (res.code == -1) {
          ElMessage({
            type: "error",
            message: res.msg,
          });
          getDetails();
        } else {
          ElMessage({
            type: "success",
            message: "禁用成功",
          });
          getList();
        }
      });
    })
    .catch((error) => {
      getList();
    });
};
const unchang = () => {
  ElMessageBox.confirm("此操作将启用该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let params = {
        status: 1,
        id: id.value,
      };
      upPermission(params).then((res) => {
        if (res.code == -1) {
          ElMessage({
            type: "error",
            message: res.msg,
          });
          getDetails();
        } else {
          ElMessage({
            type: "success",
            message: "启用成功",
          });
          getList();
        }
      });
    })
    .catch((error) => {
      getList();
    });
};
const stClick = (row) => {
  id.value = row.id;
  if (row.status == 1) {
    unchang();
  } else {
    chang();
  }
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 20px;
}
.el-input {
  width: 200px !important;
  margin-right: 10px;
}
.el-form-item {
  margin-bottom: 0 !important;
}
</style>
