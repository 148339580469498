<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-13 14:28:10
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-14 10:22:10
 * @FilePath: \chuwudemo\src\views\operationPage\replyP\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column prop="props.tableData" label="相册ID">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="相册名">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="用户">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="类型">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.type }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="图片">
        <template #default="{ row }">
          <span v-if="row != null">
            <img :src="row.url" alt="" style="width:50px;height:50px;">
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="人审状态">
        <template #default="{ row }">
          <span v-if="row != null">{{ fn[row.status] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updated_at" label="修改时间"></el-table-column>
      <el-table-column prop="props.tableData" label="操作" width="240px">
        <template #default="{ row }" style="display:flex">
          <el-button type="primary" @click="editClick(row.id)" v-if="row.status == 'reviewing'">通过</el-button>
          <el-button type="danger" @click="editsClick(row.id)" v-if="row.status == 'reviewing'">拒绝</el-button>
          <el-button type="danger" @click="deleteClick(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue"
const props = defineProps({
  tableData: {
    type: Array,
    default: []
  },
})
const fn = reactive({
  reviewing: "待审核",
  reject: "未通过",
  pass: "已通过",
})
const emit = defineEmits(['editClick', 'editsClick', 'deleteClick', 'handleSelectionChange'])
const editClick = (id) => {
  emit('editClick', id)
}
const handleSelectionChange = (e) => {
  emit('handleSelectionChange', e)
}
const editsClick = (id) => {
  emit('editsClick', id)
}
const deleteClick = (id) => {
  emit('deleteClick', id)
}
</script>