/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 11:00:05
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-25 14:25:32
 * @FilePath: \chuwu\chuwudemo\src\http\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import service from '@/utils/request'
// get请求用params,post请求用data
//导出api方法
export function userList (params) {
  return service({
    url: '/user/user',
    method: 'get',
    params: params
  })
}
export function userDetails (id) {
  return service({
    url: `/user/user/${id}`,
    method: 'get',
  })
}
export function navList () {
  return service({
    url: 'statistic/nav-tip',
    method: 'get',
  })
}

export function guardList (params) {
  return service({
    url: 'user/guard-order',
    method: 'get',
    params: params
  })
}
export function assetsList (params) {
  return service({
    url: 'user/wallet',
    method: 'get',
    params: params
  })
}
export function idolList (params) {
  return service({
    url: 'user/flow',
    method: 'get',
    params: params
  })
}
export function capitalList (params) {
  return service({
    url: 'user/recharge',
    method: 'get',
    params: params
  })
}
export function withdrawList (params) {
  return service({
    url: 'user/withdraw',
    method: 'get',
    params: params
  })
}
export function upWithdraw (params, id) {
  return service({
    url: `user/withdraw/examine/${id}`,
    method: 'put',
    params: params
  })
}
export function exchangeList (params) {
  return service({
    url: 'user/exchange',
    method: 'get',
    params: params
  })
}
export function viporderList (params) {
  return service({
    url: 'user/vip-order',
    method: 'get',
    params: params
  })
}
export function giftorderList (params) {
  return service({
    url: 'user/gift-order',
    method: 'get',
    params: params
  })
}

export function permissionList (params) {
  return service({
    url: 'user/permission',
    method: 'get',
    params: params
  })
}
export function detailsPermission (id) {
  return service({
    url: `user/permission/${id}`,
    method: 'get',
  })
}
export function editPermission (params, id) {
  return service({
    url: `user/permission/${id}`,
    method: 'put',
    params: params
  })
}
export function upPermission (params) {
  return service({
    url: `user/permission/status`,
    method: 'put',
    params: params
  })
}

export function userStatistic (id) { //单个用户统计
  return service({
    url: `user/user/statistics/${id}`,
    method: 'get',
  })
}
export function userFreeze (params) { //冻结操作
  return service({
    url: 'user/user/freeze',
    method: 'put',
    params: params
  })
}
export function userBan (params) { //封禁操作
  return service({
    url: 'user/user/ban',
    method: 'put',
    params: params
  })
}
export function upUser (params, id) { //用户修改
  return service({
    url: `user/user/${id}`,
    method: 'put',
    params: params
  })
}
export function userQuality (params) { // 优质上架操作
  return service({
    url: 'user/user/high-quality',
    method: 'put',
    params: params
  })
}
export function userPermissionDetails (id) { //权限详情
  return service({
    url: `user/permission/${id}`,
    method: 'get',
  })
}
export function upUserDetailsPermission (params, id) { //权限修改 --用户详情里面的权限详情列表的ID
  return service({
    url: `user/permission/${id}`,
    method: 'put',
    params: params
  })
}
export function upUserPermissionStatus (params) { //权限状态操作
  return service({
    url: 'user/permission/status',
    method: 'put',
    params: params
  })
}