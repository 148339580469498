<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-13 14:28:10
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-13 14:48:55
 * @FilePath: \chuwudemo\src\views\operationPage\replyP\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table
      :data="props.tableData"
      stripe
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column prop="props.tableData" label="ID">
        <template #default="{ row }">
          <span>{{ row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="评论内容">
        <template #default="{ row }">
          <span v-if="row.comment != null">{{ row.comment.content }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="回复人ID">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="回复人">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="提及用户ID">
        <template #default="{ row }">
          <span v-if="row.mentioned_user != null">{{
            row.mentioned_user.id
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="提及用户ID">
        <template #default="{ row }">
          <span v-if="row.mentioned_user != null">{{
            row.mentioned_user.name
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="回复内容">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.content }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="updated_at" label="修改时间"></el-table-column>
      <el-table-column prop="props.tableData" label="状态">
        <template #default="{ row }">
          <span v-if="row != null">{{ fn[row.reviewing_status] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="操作">
        <template #default="{ row }">
          <el-button type="primary" @click="editClick(row.id)">通过</el-button>
          <el-button type="danger" @click="editsClick(row.id)">拒绝</el-button>
          <el-button type="danger" @click="deleteClick(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue";
const props = defineProps({
  tableData: {
    type: Array,
    default: [],
  },
});
const fn = reactive({
  reviewing: "待审核",
  reject: "已拒绝",
  pass: "已通过",
});
const emit = defineEmits([
  "editClick",
  "editsClick",
  "deleteClick",
  "handleSelectionChange",
]);
const editClick = (id) => {
  emit("editClick", id);
};
const handleSelectionChange = (e) => {
  emit("handleSelectionChange", e);
};
const editsClick = (id) => {
  emit("editsClick", id);
};
const deleteClick = (id) => {
  emit("deleteClick", id);
};
</script>
