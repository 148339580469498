<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-05-21 14:25:24
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-28 16:28:52
 * @FilePath: \fromChilder\from-mode\src\views\HomeView.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="wrapperBody">
    <div class="ring">
      <!-- <i style="--clr:#00ff0a;"></i>
      <i style="--clr:#ff0057;"></i>
      <i style="--clr:#fffd44;"></i> -->
      <div class="login">
        <h2>LOGIN</h2>
        <div class="inputBx">
          <input type="text" placeholder="Username" v-model="fromData.email" />
        </div>
        <div class="inputBx">
          <input
            type="password"
            placeholder="Password"
            v-model="fromData.password"
          />
        </div>
        <div class="inputBx" @click="binGoTo">
          <input type="submit" value="登录" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { login } from "@/http/index.js";
import { ElMessage } from "element-plus";

const fromData = reactive({ email: "", password: "" });
const router = useRouter();
const binGoTo = () => {
  let params = {
    email: fromData.email,
    password: fromData.password,
  };
  login(params)
    .then((res) => {
      ElMessage({
        type: "success",
        message: "登陆成功",
      });
      router.push({
        //路由跳转
        path: "/userIndex",
        query: {},
      });
      sessionStorage.setItem("user-token", `Bearer ` + res.data.token);
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "登陆失败",
      });
    });
};
</script>
<style lang="scss" scoped>
#wrapperBody {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  background: #111;
  background-image: url("@/assets/bj.png");
  background-position: center;
  background-size: cover;
}

.ring {
  position: relative;
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%);
  border-radius: 10px;
}

.ring i {
  position: absolute;
  inset: 0;
  border: 2px solid #fff;
  transition: 0.5s;
}

.ring i:nth-child(1) {
  border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
  animation: animate 6s linear infinite;
}

.ring i:nth-child(2) {
  border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
  animation: animate 4s linear infinite;
}

.ring i:nth-child(3) {
  border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
  animation: animate2 10s linear infinite;
}

.ring:hover i {
  border: 6px solid var(--clr);
  filter: drop-shadow(0 0 20px var(--clr));
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate2 {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.login {
  position: absolute;
  width: 300px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.login h2 {
  font-size: 2em;
  color: #fff;
}

.login .inputBx {
  position: relative;
  width: 100%;
}

.login .inputBx input {
  position: relative;
  // width: 100%;
  padding: 12px 20px;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 40px;
  font-size: 1.2em;
  color: #fff;
  box-shadow: none;
  outline: none;
}

.login .inputBx input[type="submit"] {
  width: 90%;
  background: #0078ff;
  background: linear-gradient(45deg, #ff357a, #fff172);
  border: none;
  cursor: pointer;
}

.login .inputBx input::placeholder {
  color: rgba(255, 255, 255, 0.75);
}

.login .links {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.login .links a {
  color: #fff;
  text-decoration: none;
}
</style>
