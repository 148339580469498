<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-12 09:29:47
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-27 14:56:12
 * @FilePath: \chuwudemo\src\views\userPage\user\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 17:00:25
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-12 10:33:57
 * @FilePath: \chuwudemo\src\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%">
      <el-table-column prop="headimgurl" label="头像">
        <template #default="{ row }">
          <img :src="row.headimgurl" alt="" style="width: 50px; height: 50px" />
        </template>
      </el-table-column>
      <el-table-column
        :prop="item.prop"
        :label="item.label"
        v-for="(item, index) in cloData"
        :key="index"
      >
      </el-table-column>
      <!-- <el-table-column prop="sex" label="性别">
        <template #default="{ row }">
          <span>
            {{ fn[row.sex] }}
          </span>
        </template>
      </el-table-column> -->
      <el-table-column prop="is_real_person" label="是否真人(商家)">
        <template #default="{ row }">
          <el-switch
            v-model="row.is_real_person"
            disabled="true"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="2"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="is_high_quality" label="是否优质用户">
        <template #default="{ row }">
          <el-switch
            v-model="row.is_high_quality"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="0"
            @change="highClick(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="is_online" label="是否在线">
        <template #default="{ row }">
          <el-switch
            v-model="row.is_online"
            disabled="true"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="is_freeze" label="是否冻结">
        <template #default="{ row }">
          <el-switch
            v-model="row.is_freeze"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="0"
            @change="freeClick(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="is_ban" label="是否封禁">
        <template #default="{ row }">
          <el-switch
            v-model="row.is_ban"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="0"
            @change="banClick(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" width="250px">
        <template #default="{ row }">
          <el-button type="primary" @click="detailsClick(row.id)"
            >详情</el-button
          >
          <el-button type="primary" @click="updateClick(row.id)"
            >修改</el-button
          >
          <el-button type="warning" @click="assetClick(row)">资产</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="高级操作" width="250px">
        <template #default="{ row }">
          <el-button type="danger" @click="juryClick(row.id)"
            >(权限操作)</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue";
const props = defineProps({
  tableData: {
    type: Array,
    default: [],
  },
});
const fn = reactive({ 0: "未知", 1: "男", 2: "女" });
const cloData = reactive([
  { prop: "name", label: "昵称" },
  { prop: "phone_number", label: "手机号" },
  { prop: "user_id", label: "用户ID" },
  { prop: "platform", label: "平台" },
  { prop: "uuid_type", label: "用户平台" },
  { prop: "location", label: "位置" },
  { prop: "signature", label: "签名" },
  // { prop: "created_start", label: "注册实际-开始" },
  // { prop: "created_end", label: "注册实际-结束" },
]); //列表的头

const emit = defineEmits([
  "detailsClick",
  "updateClick",
  "assetClick",
  "freeClick",
  "banClick",
  "juryClick",
]);
const juryClick = (id) => {
  emit("juryClick", id);
};
const detailsClick = (id) => {
  emit("detailsClick", id);
};
const updateClick = (id) => {
  emit("updateClick", id);
};
const assetClick = (row) => {
  emit("assetClick", row);
};
const freeClick = (row) => {
  emit("freeClick", row);
};
const banClick = (row) => {
  emit("banClick", row);
};
const highClick = (row) => {
  emit("highClick", row);
};
</script>
<style lang="scss"></style>
