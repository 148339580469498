<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-13 10:45:08
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-27 09:51:13
 * @FilePath: \chuwudemo\src\views\operationPage\chattingRecords\chat.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="search">
      <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入发送者ID"
        v-model="searchValue.form_id"
      ></el-input>
      <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入接收者ID"
        v-model="searchValue.to_id"
      ></el-input>
      <el-button type="primary" style="margin-left: 10px" @click="searchClick"
        >搜索</el-button
      >
      <el-button type="primary" @click="restClick">重置查询条件</el-button>
      <el-form-item label="消息类型" style="margin-left: 5px; width: 200px">
        <el-select
          v-model="searchValue.type"
          placeholder="请选择"
          style="width: 240px"
          @change="tyClick"
        >
          <el-option label="文本" value="text" />
          <el-option label="图片" value="image" />
          <el-option label="视频" value="video" />
          <el-option label="音频" value="sound" />
          <el-option label="商品" value="goods" />
        </el-select>
      </el-form-item>
    </div>
    <div class="content">
      <tableDate :tableData="tableData" @removeClick="removeClick"> </tableDate>
    </div>
    <div>
      <pagination
        :painData="painData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
      </pagination>
    </div>
    <div></div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import { chatList, removeChat } from "@/http/operation.js";
import { ElMessage, ElMessageBox } from "element-plus";
import tableDate from "./components/tableData.vue";
import search from "@/components/search.vue";
import pagination from "@/components/pagination.vue";
const tableData = ref();
const detailData = ref();
const dialogTableVisible = ref(false);
const id = ref();
const painData = reactive({
  total: 0, //一共多少条
  limit: 10, //一页多少条
  currentPage: 1, //第几页
});
const searchValue = reactive({
  form_id: "", //用户ID
  to_id: "", //类型
  type: "", //金币去向
  is_group: "", //订单号
});
onMounted(() => {
  getList();
});
const tyClick = () => {
  const params = {
    type: searchValue.type,
    page: painData.currentPage,
    limit: painData.limit,
  };
  chatList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const searchClick = () => {
  const params = {
    to_id: searchValue.to_id,
    form_id: searchValue.form_id,
    type: searchValue.type,
    is_group: searchValue.is_group,
    page: painData.currentPage,
    limit: painData.limit,
  };
  chatList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};

const restClick = () => {
  getList();
  Object.keys(searchValue).forEach((key) => {
    searchValue[key] = "";
  });
};
const getList = () => {
  const params = {
    page: painData.currentPage,
    limit: painData.limit,
  };
  chatList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const removeClick = (e) => {
  console.log(e);
  ElMessageBox.confirm("此操作将撤回该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      removeChat(e)
        .then((res) => {
          if (res.code == -1) {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          } else {
            ElMessage({
              type: "success",
              message: "撤回成功",
            });
            getList();
          }
        })
        .catch(() => {});
    })
    .catch(() => {});
};
const handleSizeChange = (val) => {
  //上一页
  painData.currentPage = val;
  getList();
};
const handleCurrentChange = (val) => {
  //下一页
  painData.currentPage = val;
  getList();
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 20px;
}
.el-input {
  width: 200px !important;
  margin-right: 10px;
}
.el-form-item {
  margin-bottom: 0 !important;
}
</style>
