<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%">
      <el-table-column prop="props.tableData" label="ID">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="举报人ID">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="举报人">
        <template #default="{ row }">
          <span v-if="row.user != null">{{ row.user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="被举报人ID">
        <template #default="{ row }">
          <span v-if="row.report_user != null">{{ row.report_user.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="被举报人">
        <template #default="{ row }">
          <span v-if="row.report_user != null">{{ row.report_user.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="动态内容">
        <template #default="{ row }">
          <span v-if="row.post != null">{{ row.post.content }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="举报原因">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.reason }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="回馈信息">
        <template #default="{ row }">
          <span v-if="row != null">{{ row.callback }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="updated_at" label="修改时间"></el-table-column>
      <el-table-column prop="props.tableData" label="操作">
        <template #default="{ row }">
          <el-button type="primary" @click="editClick(row)">回馈</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive } from "vue";
const props = defineProps({
  tableData: {
    type: Array,
    default: [],
  },
});
const fn = reactive({
  reviewing: "待审核",
  reject: "已拒绝",
  pass: "已通过",
  paid: "已支付",
});
const emit = defineEmits(["editClick"]);
const editClick = (e) => {
  emit("editClick", e);
};
</script>
