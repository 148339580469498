<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 16:52:14
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-27 18:02:05
 * @FilePath: \chuwudemo\src\views\userPage\guard.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div>
      <div class="search">
        <el-input
          type="text"
          aria-setsize="small"
          placeholder="请输入昵称"
          v-model="searchValue.name"
        ></el-input>
        <el-input
          type="text"
          aria-setsize="small"
          placeholder="请输入手机号"
          v-model="searchValue.tel"
        ></el-input>
        <el-input
          type="text"
          aria-setsize="small"
          placeholder="请输入用户ID"
          v-model="searchValue.userId"
        ></el-input>
        <el-button type="primary" style="margin-left: 10px" @click="searchClick"
          >搜索</el-button
        >
        <el-button type="primary" @click="restClick">重置查询条件</el-button>
        <br />
        <el-form-item label="开始时间" style="margin-left: 5px">
          <el-date-picker
            v-model="searchValue.created_start"
            type="date"
            placeholder="选择开始时间"
            size="200px"
            @change="liaClick"
          />
        </el-form-item>
        <el-form-item label="结束时间" style="margin-left: 5px">
          <el-date-picker
            v-model="searchValue.created_end"
            type="date"
            placeholder="选择结束时间"
            size="200px"
            @change="liaClick"
          />
        </el-form-item>
        <el-form-item label="平台" style="margin-left: 5px">
          <el-select
            v-model="searchValue.platform"
            placeholder="请选择"
            style="width: 240px"
            @change="liaClick"
          >
            <el-option label="IOS" value="iPhone" />
            <el-option label="安卓" value="Android" />
            <el-option label="未知" value="Unknown" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否真人" style="margin-left: 5px">
          <el-switch
            v-model="searchValue.is_real_person"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="2"
            @change="liaClick"
          />
        </el-form-item>
        <el-form-item label="是否优质用户" style="margin: 0 5px 0 5px">
          <el-switch
            v-model="searchValue.is_high_quality"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="2"
            @change="liaClick"
          />
        </el-form-item>
        <el-form-item label="是否在线">
          <el-switch
            v-model="searchValue.is_online"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="2"
            @change="liaClick"
          />
        </el-form-item>
      </div>
    </div>
    <!-- 比如这个页面需要详情和删除按钮 -->
    <div class="content">
      <tableDate
        :cloData="cloData"
        :tableData="tableData"
        @detailsClick="detailsClick"
        @updateClick="updateClick"
        @assetClick="assetClick"
        @freeClick="freeClick"
        @banClick="banClick"
        @highClick="highClick"
        @juryClick="juryClick"
      >
      </tableDate>
    </div>
    <div>
      <pagination
        :painData="painData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
      </pagination>
    </div>
    <div>
      <diaLog
        v-model:dialogTableVisible="dialogTableVisible"
        :detailData="detailData"
        :status="status"
        :upData="upData"
        @quXiao="quXiao"
        @sheHe="sheHe"
        :assetData="assetData"
        :nameS="nameS"
      ></diaLog>
    </div>
    <div>
      <jurdiaLog
        v-model:quanXian="quanXian"
        v-model:dialogTableVisible="dialogTableVisible"
        :PermissionList="PermissionList"
        @jurClick="jurClick"
        :qxStatus="qxStatus"
        :quanXianList="quanXianList"
        @changSelect="changSelect"
        @upClick="upClick"
        :form="form"
        @qxShe="qxShe"
        @quan="quan"
        :status="status"
      ></jurdiaLog>
    </div>
  </div>
</template>
<script setup>
// 使用封装的表单做到所有页面只需要调这一个组件包括内部定义的按钮事件
import { ref, reactive, onMounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  userList,
  userDetails,
  userStatistic,
  userFreeze,
  userBan,
  upUser,
  userQuality,
  userPermissionDetails,
  upUserDetailsPermission,
  upUserPermissionStatus,
} from "@/http/user.js";
import tableDate from "./components/tableData.vue";
import pagination from "@/components/pagination.vue";
import diaLog from "./components/dialog";
import jurdiaLog from "./components/jurdialog";
const tableData = ref();
const detailData = ref();
const status = ref(0);
const assetData = ref();
const nameS = ref();
const dialogTableVisible = ref(false);
const quanXian = ref(false);
const qxStatus = ref(0);
const quanXianList = ref();
const id = ref();
const row = ref();
const form = reactive({
  status: "",
  start_at: "",
  end_at: "",
});
const searchValue = reactive({
  name: "",
  tel: "",
  userId: "",
  platform: "", //平台
  is_real_person: 2, //是否真人 1是 2不是 下面一样
  is_high_quality: 2, //是否优质用户
  is_online: 2, //是否在线
  created_start: "",
  created_end: "",
});
const PermissionList = ref();
const painData = reactive({
  total: 0, //一共多少条
  limit: 10, //一页多少条
  currentPage: 1, //第几页
});
const upData = reactive({
  name: "",
  signature: "",
  is_freeze: "",
  is_ban: "",
  is_high_quality: "",
});

const liaClick = () => {
  const params = {
    is_high_quality: searchValue.is_high_quality,
    is_real_person: searchValue.is_real_person,
    platform: searchValue.platform,
    created_end: searchValue.created_end,
    created_start: searchValue.created_start,

    is_online: searchValue.is_online,
    page: painData.currentPage,
    limit: painData.limit,
  };
  userList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const searchClick = () => {
  const params = {
    name: searchValue.name,
    tel: searchValue.tel,
    user_id: searchValue.userId,
    platform: searchValue.platform,
    is_real_person: searchValue.is_real_person,
    is_high_quality: searchValue.is_high_quality,
    is_online: searchValue.is_online,
    created_start: searchValue.created_start,
    created_end: searchValue.created_end,
    page: painData.currentPage,
    limit: painData.limit,
  };
  userList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const restClick = () => {
  getList();
  searchValue.name = "";
  searchValue.tel = "";
  searchValue.userId = "";
  searchValue.is_real_person = 2;
  searchValue.is_high_quality = 2;
  searchValue.is_online = 2;
  searchValue.platform = "";
  searchValue.created_start = "";
  searchValue.created_end = "";
};
onMounted(() => {
  getList();
});
const detailsClick = (e) => {
  dialogTableVisible.value = true;
  id.value = e;
  status.value = 0;
  getDetails();
};
const getDetails = () => {
  userDetails(id.value)
    .then((res) => {
      detailData.value = [res.data];
      upData.name = res.data.name;
      upData.signature = res.data.signature;
      upData.is_freeze = res.data.is_freeze;
      upData.is_ban = res.data.is_ban;
      upData.is_high_quality = res.data.is_high_quality;
      PermissionList.value = res.data.permissions;
    })
    .catch((err) => {});
};
const getList = () => {
  const params = {
    page: painData.currentPage,
    limit: painData.limit,
  };
  userList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const updateClick = (e) => {
  status.value = 1;
  dialogTableVisible.value = true;
  id.value = e;
  getDetails();
};
const sheHe = (from) => {
  ElMessageBox.confirm("此操作将修改该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    upUser(from, id.value).then((res) => {
      if (res.code == -1) {
        ElMessage({
          type: "error",
          message: res.msg,
        });
        dialogTableVisible.value = false;
        getList();
      } else {
        ElMessage({
          type: "success",
          message: "修改成功",
        });
        dialogTableVisible.value = false;
        getList();
      }
    });
  });
};
const handleSizeChange = (val) => {
  //上一页
  painData.currentPage = val;
  getList();
};
const handleCurrentChange = (val) => {
  //下一页
  painData.currentPage = val;
  getList();
};
const quXiao = () => {
  dialogTableVisible.value = false;
};
const assetClick = (e) => {
  id.value = e.id;
  nameS.value = e.name;
  status.value = 2;
  dialogTableVisible.value = true;
};
const getAssets = () => {
  //获取单用户资产详情
  userStatistic(id.value)
    .then((res) => {
      assetData.value = [res.data];
      console.log(res.data);
    })
    .catch((err) => {});
};
const free = () => {
  //冻结
  ElMessageBox.confirm("此操作将冻结该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let params = {
        freeze: 1,
        id: id.value,
      };
      userFreeze(params).then((res) => {
        if (res.code == -1) {
          ElMessage({
            type: "error",
            message: res.msg,
          });
          getList();
        } else {
          ElMessage({
            type: "success",
            message: "冻结成功",
          });
          getList();
        }
      });
    })
    .catch((error) => {
      getList();
    });
};
const unfree = () => {
  //解冻
  ElMessageBox.confirm("此操作将解冻该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let params = {
        freeze: 0,
        id: id.value,
      };
      userFreeze(params).then((res) => {
        if (res.code == -1) {
          ElMessage({
            type: "error",
            message: res.msg,
          });
          getList();
        } else {
          ElMessage({
            type: "success",
            message: "解冻成功",
          });
          getList();
        }
      });
    })
    .catch((error) => {
      getList();
    });
};
const freeClick = (e) => {
  //冻结 / 解冻
  id.value = e.id;
  if (e.is_freeze == 1) {
    free();
  } else {
    unfree();
  }
};
const ban = () => {
  //封禁
  ElMessageBox.confirm("此操作将封禁该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let params = {
        ban: 1,
        id: id.value,
      };
      userBan(params).then((res) => {
        if (res.code == -1) {
          ElMessage({
            type: "error",
            message: res.msg,
          });
          getList();
        } else {
          ElMessage({
            type: "success",
            message: "封禁成功",
          });
          getList();
        }
      });
    })
    .catch((error) => {
      getList();
    });
};
const unban = () => {
  //封禁
  ElMessageBox.confirm("此操作将解封该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let params = {
        ban: 0,
        id: id.value,
      };
      userBan(params).then((res) => {
        if (res.code == -1) {
          ElMessage({
            type: "error",
            message: res.msg,
          });
          getList();
        } else {
          ElMessage({
            type: "success",
            message: "解封成功",
          });
          getList();
        }
      });
    })
    .catch((error) => {
      getList();
    });
};
const banClick = (e) => {
  id.value = e.id;
  if (e.is_ban == 1) {
    ban();
  } else {
    unban();
  }
};
const high = () => {
  //冻结
  ElMessageBox.confirm("此操作将冻结该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let params = {
        high_quality: 1,
        id: id.value,
      };
      userQuality(params).then((res) => {
        if (res.code == -1) {
          ElMessage({
            type: "error",
            message: res.msg,
          });
          getList();
        } else {
          ElMessage({
            type: "success",
            message: "冻结成功",
          });
          getList();
        }
      });
    })
    .catch((error) => {
      getList();
    });
};
const unhigh = () => {
  //冻结
  ElMessageBox.confirm("此操作将解冻该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let params = {
        high_quality: 0,
        id: id.value,
      };
      userQuality(params).then((res) => {
        if (res.code == -1) {
          ElMessage({
            type: "error",
            message: res.msg,
          });
          getList();
        } else {
          ElMessage({
            type: "success",
            message: "解冻成功",
          });
          getList();
        }
      });
    })
    .catch((error) => {
      getList();
    });
};
const highClick = (e) => {
  id.value = e.id;
  if (e.is_high_quality == 1) {
    high();
  } else {
    unhigh();
  }
};
const juryClick = (e) => {
  id.value = e;
  getDetails();
  status.value = 4;
  dialogTableVisible.value = true;
};
const jurClick = (id) => {
  //用户权限详情
  qxStatus.value = 0;
  userPermissionDetails(id).then((res) => {
    quanXian.value = true;
    quanXianList.value = [res.data];
  });
};
const changSelect = (row) => {
  // id.value = row.id;
  if (row.status == 1) {
    changWe(row);
  } else {
    unchangWe(row);
  }
};
const changWe = (row) => {
  ElMessageBox.confirm("此操作将启用该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let params = {
        status: 1,
        id: row.id,
      };
      upUserPermissionStatus(params).then((res) => {
        if (res.code == -1) {
          ElMessage({
            type: "error",
            message: res.msg,
          });
        } else {
          ElMessage({
            type: "success",
            message: "启用成功",
          });
        }
      });
    })
    .catch((error) => {
      row.status = !row.status;
    });
};
const unchangWe = (row) => {
  ElMessageBox.confirm("此操作将禁用该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      let params = {
        status: 0,
        id: row.id,
      };
      upUserPermissionStatus(params).then((res) => {
        if (res.code == -1) {
          ElMessage({
            type: "error",
            message: res.msg,
          });
        } else {
          ElMessage({
            type: "success",
            message: "禁用成功",
          });
        }
      });
    })
    .catch((error) => {
      row.status = 1;
      console.log("1111", row.status);
    });
};
const upClick = (row) => {
  //用户权限修改
  id.value = row.id;
  qxStatus.value = 1;
  quanXian.value = true;
  form.status = row.status;
  form.start_at = row.start_at;
  form.end_at = row.end_at;
};
const qxShe = (form) => {
  ElMessageBox.confirm("此操作将修改该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      upUserDetailsPermission(form, id.value).then((res) => {
        if (res.code == -1) {
          ElMessage({
            type: "error",
            message: res.msg,
          });
          getDetails();
        } else {
          ElMessage({
            type: "success",
            message: "修改成功",
          });
          quanXian.value = false;
        }
      });
    })
    .catch((error) => {});
};
const quan = () => {
  quanXian.value = false;
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 20px;
}
.el-input {
  width: 200px !important;
  margin-right: 10px;
}
.el-form-item {
  margin-bottom: 0 !important;
}
.search {
  display: flex;
  flex-wrap: wrap;
}
</style>
