<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-12 09:29:47
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-26 15:49:06
 * @FilePath: \chuwudemo\src\views\userPage\user\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 17:00:25
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-12 10:33:57
 * @FilePath: \chuwudemo\src\components\tableData.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-table :data="props.tableData" stripe style="width: 100%">
      <el-table-column prop="props.tableData" label="ID">
        <template #default="{ row }">
          <span>
            {{ row.id }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="User_ID">
        <template #default="{ row }">
          <span>
            {{ row.user_id }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="NAME">
        <template #default="{ row }">
          <span>
            {{ fn[row.name] }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="props.tableData" label="权限状态">
        <template #default="{ row }">
          <el-switch
            v-model="row.status"
            class="ml-2"
            style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            "
            :active-value="1"
            :inactive-value="0"
            @change="stClick(row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column prop="props.tableData" label="操作">
        <template #default="{ row }">
          <el-button type="primary" @click="detailsClick(row.id)"
            >详情</el-button
          >
          <el-button type="primary" @click="editClick(row.id)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script setup>
import { reactive, ref } from "vue";

const props = defineProps({
  tableData: {
    type: Array,
    default: [],
  },
});
const fn = reactive({
  chat: "禁止聊天",
  reply: "禁止回复评论",
  comment: "禁止评论",
  post: "禁止发布动态",
  goods: "禁止发布商品",
  withdraw: "禁止提现",
  album: "禁止发布照片",
  index: "禁止首页展示",
  sell_goods: "禁止出售商品",
});

const emit = defineEmits(["detailsClick", "stClick", "editClick"]);
const detailsClick = (id) => {
  emit("detailsClick", id);
};
const stClick = (row) => {
  emit("stClick", row);
};
const editClick = (id) => {
  emit("editClick", id);
};
</script>
<style lang="scss"></style>
