<!--
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-12 10:49:44
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-26 17:33:38
 * @FilePath: \chuwudemo\src\views\operationPage\photoAlbum\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE相册
-->
<template>
  <div>
    <div class="search">
      <el-input
        type="text"
        aria-setsize="small"
        placeholder="请输入ID"
        v-model="searchValue.user_id"
      ></el-input>
      <el-button type="primary" style="margin-left: 10px" @click="searchClick"
        >搜索</el-button
      >
      <el-button type="primary" @click="restClick">重置查询条件</el-button>
    </div>
    <div class="content">
      <tableDate
        :tableData="tableData"
        @deleteClick="deleteClick"
        @detailsClick="detailsClick"
      >
      </tableDate>
    </div>
    <div>
      <pagination
        :painData="painData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
      </pagination>
    </div>
    <div>
      <diaLog
        v-model:dialogTableVisible="dialogTableVisible"
        :detailData="detailData"
      ></diaLog>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import { photoList, photoDetails, deletePhoto } from "@/http/operation.js";
import { ElMessage, ElMessageBox } from "element-plus";
import tableDate from "./components/tableData.vue";
import search from "@/components/search.vue";
import pagination from "@/components/pagination.vue";
import diaLog from "./components/dialog";
const tableData = ref();
const detailData = ref();
const dialogTableVisible = ref(false);
const id = ref();
const painData = reactive({
  total: 0, //一共多少条
  limit: 10, //一页多少条
  currentPage: 1, //第几页
});
const searchValue = reactive({
  user_id: "", //用户ID
});
onMounted(() => {
  getList();
});
const searchClick = () => {
  const params = {
    user_id: searchValue.user_id,
    page: painData.currentPage,
    limit: painData.limit,
  };
  photoList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const restClick = () => {
  getList();
  Object.keys(searchValue).forEach((key) => {
    searchValue[key] = "";
  });
};
const getList = () => {
  const params = {
    page: painData.currentPage,
    limit: painData.limit,
  };
  photoList(params).then((res) => {
    tableData.value = res.data.list;
    painData.total = res.data.total;
  });
};
const getDetails = () => {
  photoDetails(id.value)
    .then((res) => {
      detailData.value = [res.data];
    })
    .catch((err) => {});
};
const detailsClick = (e) => {
  dialogTableVisible.value = true;
  id.value = e;
  getDetails();
};
const deleteClick = (e) => {
  ElMessageBox.confirm("此操作将删除该数据, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      deletePhoto(e)
        .then((res) => {
          if (res.code == 200) {
            ElMessage({
              type: "success",
              message: "删除成功",
            });
            getList();
          } else {
            ElMessage({
              type: "error",
              message: "删除失败",
            });
          }
        })
        .catch(() => {});
    })
    .catch(() => {});
};
const handleSizeChange = (val) => {
  //上一页
  painData.currentPage = val;
  getList();
};
const handleCurrentChange = (val) => {
  //下一页
  painData.currentPage = val;
  getList();
};
</script>
<style lang="scss" scoped>
.content {
  margin-top: 20px;
}
.el-input {
  width: 200px !important;
  margin-right: 10px;
}
.el-form-item {
  margin-bottom: 0 !important;
}
</style>
