/*
 * @Author: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @Date: 2024-06-11 10:32:33
 * @LastEditors: DESKTOP-2022OQU\Administrator 3214322091@qq.com
 * @LastEditTime: 2024-06-28 16:42:20
 * @FilePath: \chuwu\chuwudemo\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import login from "@/views/login"
import userIndex from "@/views/homePage/userIndex"
import index from "@/views/homePage/index"
import basicsRouts from "./basics"
import userRouts from "./user.js"
import operationRouts from "./operation.js"
import allRouts from "./allocation.js"
import merRouts from "./refund.js"
const routes = [
  {
    path: '/',
    name: '登录',
    component: login,
  },
  {
    path: '/userIndex',
    component: index,
    meta: { title: "首页" },
    children: [
      {
        path: '',
        name: '首页',
        component: userIndex,
        meta: { title: "首页" },
      },
      ...basicsRouts, ...userRouts, ...operationRouts, ...allRouts, ...merRouts
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export default router
